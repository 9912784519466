import {AssetTree} from "../../backend/interfaces/AssetTree.ts";
import React from "react";
import {ItemTable} from "../ItemTable.tsx";
import {Collapsable} from "../Collapsable.tsx";
import {ItemPixmap} from "../Pixmap.tsx";

interface AssetTreeComponentProps {
    asset: AssetTree
}

interface AssetTreeComponentState {
}

export class AssetTreeComponent extends React.Component<AssetTreeComponentProps, AssetTreeComponentState> {
    constructor(props: any) {
        super(props);
    }

    render() {
        if (this.props.asset.children.length == 0) return <></>
        return <>
            <ItemTable
                items={this.props.asset.children
                    .map(v => v.item)
                    .filter(v => v != undefined)
                    .map(v => v!!)}
                showBlueprint={true}
                showPrices={false}
            />
            <ol>
                {this.props.asset.children
                    .filter(v => (
                            v.container.containerName != "None" &&
                            v.container.containerName != "" &&
                            v.container.children.length > 0
                        )
                    )
                    .map(c => {
                            let displayName = c.item?.item.item || ""
                            displayName += " (" + c.container.containerName + ")"

                            return <li>
                                <Collapsable title={displayName}>
                                    <ItemPixmap
                                        item={c.item?.item}
                                        name={displayName}
                                    />
                                    <AssetTreeComponent
                                        asset={c.container}
                                    />
                                </Collapsable>
                            </li>
                        }
                    )}
            </ol>
        </>
    }
}