import {Observable} from "../util/Observable.ts";
import {BACKEND} from "./Constants.ts";

export interface LoginData {
    loggedIn: boolean;
    username: string;
    money: number;
}

export function checkLogin(): Promise<LoginData> {
    return fetch(BACKEND + "checkLogin/", {
        method: "GET",
        credentials: 'include'
    })
        .then(value => value.json())
}

export function login(username: string, password: string): Promise<LoginData> {
    let formData = new URLSearchParams();
    formData.append("username", username);
    formData.append("password", password);

    return fetch(BACKEND + "user/login", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then(value => value.json());
}

export function logout(): Promise<Response> {
    let formData = new URLSearchParams();

    return fetch(BACKEND + "user/logout", {
        method: 'POST',
        body: formData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    }).then(value => value.json());
}

export const LoggedInObservable = new Observable<LoginData>({initialValue: {loggedIn: false, username: "", money: 0}});