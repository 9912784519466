import React from "react";
import {CraftingConfiguration} from "../../backend/interfaces/CraftingConfiguration";
import {StructureType} from "../../backend/interfaces/StructureType";
import {Collapsable} from "../Collapsable";
import {CraftingConfigurationObservable} from "./CraftingConfigurationObservable.ts";
import {getAllSystems} from "../../backend/Systems.ts";
import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import {SystemTypeahead} from "../SystemTypeahead.tsx";

interface CraftingConfigurationProps {
}

interface CraftingConfigurationState {
    configuration?: CraftingConfiguration;
    allSystems: SolarSystem[]
}

export class CraftingConfigurationComponent extends React.Component<CraftingConfigurationProps, CraftingConfigurationState> {
    constructor(props: CraftingConfigurationProps, context: any) {
        super(props, context);
        this.state = {
            allSystems: []
        }
    }

    private configurationListener = (value: CraftingConfiguration) => {
        this.setState({
            configuration: value,
        });
    }

    componentDidMount() {
        getAllSystems().then(v => this.setState({allSystems: v}))
        CraftingConfigurationObservable.subscribe(this.configurationListener);
    }

    componentWillUnmount() {
        CraftingConfigurationObservable.unsubscribe(this.configurationListener);
    }

    private _updateConfig(key: string, extractedValue: any) {
        let updatedConfiguration = {...this.state.configuration!!}
        updatedConfiguration[key] = extractedValue
        CraftingConfigurationObservable.setValue(updatedConfiguration)
    }

    private updateConfig(key: string, extractor: (s: string) => any) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newValue = extractor(ev.target.value);
            this._updateConfig(key, newValue);
        }
    }

    private updateConfigTypeahead(key: string, extractor: (s: any) => any) {
        return (selected: any) => {
            let newValue = extractor(selected);
            this._updateConfig(key, newValue);
        }
    }

    private updateConfigCheck(key: string) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newRawValue = ev.target.checked;
            let newValue = newRawValue;
            this._updateConfig(key, newValue);
        }
    }

    private updateConfigSelect(key: string, extractor: (s?: string) => any) {
        return (ev: React.ChangeEvent<HTMLSelectElement>) => {
            let newRawValue = ev.target.selectedOptions.item(0)?.value;
            let newValue = extractor(newRawValue);
            this._updateConfig(key, newValue);
        }
    }

    private SkillLevel(name: string) {
        // @ts-ignore
        let defaultValue = this.state.configuration[name];
        return <>
            <label htmlFor={name}>{name}</label>
            <input name={name}
                   onChange={this.updateConfig(name, Number.parseInt)}
                   defaultValue={defaultValue}
                   min={0}
                   max={5}
            />
        </>
    }

    render() {
        if (this.state.configuration == undefined) return <></>

        return (
            <>

                <label htmlFor="reactionsSystem">reactionsSystem</label>
                <SystemTypeahead
                    onChange={this.updateConfigTypeahead("reactionsSystem", v => v.systemName)}
                    allSystems={this.state.allSystems}
                    initialValue={this.state.configuration.reactionsSystem}
                />
                <br/>
                <label htmlFor="structureType">structureType</label>
                <select name="structureType"
                        onChange={this.updateConfigSelect("structureType", s => s as StructureType)}
                        defaultValue={this.state.configuration.structureType}
                >
                    <option value="ASTRAHUS">ASTRAHUS</option>
                    <option value="FORTIZAR">FORTIZAR</option>
                    <option value="DRACCOUS_FORTIZAR">DRACCOUS_FORTIZAR</option>
                    <option value="HORIZON_FORTIZAR">HORIZON_FORTIZAR</option>
                    <option value="MARGINIS_FORTIZAR">MARGINIS_FORTIZAR</option>
                    <option value="MOREAU_FORTIZAR">MOREAU_FORTIZAR</option>
                    <option value="PROMETHEUS_FORTIZAR">PROMETHEUS_FORTIZAR</option>
                    <option value="KEEPSTAR">KEEPSTAR</option>
                    <option value="ATHANOR">ATHANOR</option>
                    <option value="TATARA">TATARA</option>
                    <option value="RAITARU">RAITARU</option>
                    <option value="AZBEL">AZBEL</option>
                    <option value="SOTIYO">SOTIYO</option>
                    <option value="NPC">NPC</option>
                </select>
                <br/>
                <br/>
                <label htmlFor="shippingCostInputPerM3">shippingCostInputPerM3</label>
                <input name="shippingCostInputPerM3"
                       onChange={this.updateConfig("shippingCostInputPerM3", Number.parseFloat)}
                       defaultValue={this.state.configuration.shippingCostInputPerM3}
                />
                <br/>
                <label htmlFor="shippingCostInputPerCollateral">shippingCostInputPerCollateral</label>
                <input name="shippingCostInputPerCollateral"
                       onChange={this.updateConfig("shippingCostInputPerCollateral", Number.parseFloat)}
                       defaultValue={this.state.configuration.shippingCostInputPerCollateral}
                />
                <br/>
                <label htmlFor="shippingCostOutputPerM3">shippingCostOutputPerM3</label>
                <input name="shippingCostOutputPerM3"
                       onChange={this.updateConfig("shippingCostOutputPerM3", Number.parseFloat)}
                       defaultValue={this.state.configuration.shippingCostOutputPerM3}
                />
                <br/>
                <label htmlFor="shippingCostOutputPerCollateral">shippingCostOutputPerCollateral</label>
                <input name="shippingCostOutputPerCollateral"
                       onChange={this.updateConfig("shippingCostOutputPerCollateral", Number.parseFloat)}
                       defaultValue={this.state.configuration.shippingCostOutputPerCollateral}
                />
                <br/>
                <br/>
                <label htmlFor="compositeReactionsTax">compositeReactionsTax</label>
                <input name="compositeReactionsTax"
                       onChange={this.updateConfig("compositeReactionsTax", Number.parseFloat)}
                       defaultValue={this.state.configuration.compositeReactionsTax}/>
                <br/>
                <label htmlFor="hybridReactionsTax">hybridReactionsTax</label>
                <input name="hybridReactionsTax" onChange={this.updateConfig("hybridReactionsTax", Number.parseFloat)}
                       defaultValue={this.state.configuration.hybridReactionsTax}/>
                <br/>
                <label htmlFor="manufacturingTax">manufacturingTax</label>
                <input name="manufacturingTax" onChange={this.updateConfig("manufacturingTax", Number.parseFloat)}
                       defaultValue={this.state.configuration.manufacturingTax}/>
                <br/>
                <br/>
                <label htmlFor="securityIndex">securityIndex</label>
                <select name="securityIndex"
                        onChange={this.updateConfigSelect("securityIndex", v => v)}
                        defaultValue={this.state.configuration.securityIndex}>
                    <option value="HIGH_SEC">HIGH_SEC</option>
                    <option value="LOW_SEC">LOW_SEC</option>
                    <option value="NULL_SEC">NULL_SEC</option>
                    <option value="WORMHOLE">WORMHOLE</option>
                </select>
                <br/>
                <br/>
                <label htmlFor="rigLevelCompositeReactions">rigLevelCompositeReactions</label>
                <input name="rigLevelCompositeReactions"
                       onChange={this.updateConfig("rigLevelCompositeReactions", Number.parseInt)}
                       defaultValue={this.state.configuration.rigLevelCompositeReactions}
                       min={0}
                       max={2}
                />
                <br/>
                <label htmlFor="rigLevelHybridReactions">rigLevelHybridReactions</label>
                <input name="rigLevelHybridReactions"
                       onChange={this.updateConfig("rigLevelHybridReactions", Number.parseInt)}
                       defaultValue={this.state.configuration.rigLevelHybridReactions}
                       min={0}
                       max={2}
                />
                <br/>
                <label htmlFor="rigLevelManufacturing">rigLevelManufacturing</label>
                <input name="rigLevelManufacturing"
                       onChange={this.updateConfig("rigLevelManufacturing", Number.parseInt)}
                       defaultValue={this.state.configuration.rigLevelManufacturing}
                       min={0}
                       max={2}
                />
                <br/>
                <br/>
                <label htmlFor="producePI_0">produce PI 0</label>
                <input name="producePI_0"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("producePI_0")}
                       defaultChecked={this.state.configuration.producePI_0}/>
                <br/>
                <label htmlFor="producePI_1">produce PI 1</label>
                <input name="producePI_1"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("producePI_1")}
                       defaultChecked={this.state.configuration.producePI_1}/>
                <br/>
                <label htmlFor="producePI_2">produce PI 2</label>
                <input name="producePI_2"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("producePI_2")}
                       defaultChecked={this.state.configuration.producePI_2}/>
                <br/>
                <label htmlFor="producePI_3">produce PI 3</label>
                <input name="producePI_3"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("producePI_3")}
                       defaultChecked={this.state.configuration.producePI_3}/>
                <br/>
                <label htmlFor="producePI_4">produce PI 4</label>
                <input name="producePI_4"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("producePI_4")}
                       defaultChecked={this.state.configuration.producePI_4}/>
                <br/>
                <label htmlFor="produceReactions">produce Reactions</label>
                <input name="produceReactions"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceReactions")}
                       defaultChecked={this.state.configuration.produceReactions}/>
                <br/>
                <label htmlFor="produceFuelBlocks">produce Fuel Blocks</label>
                <input name="produceFuelBlocks"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceFuelBlocks")}
                       defaultChecked={this.state.configuration.produceFuelBlocks}/>
                <br/>
                <label htmlFor="produceComponents">produce Components</label>
                <input name="produceComponents"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceComponents")}
                       defaultChecked={this.state.configuration.produceComponents}/>
                <br/>
                <label htmlFor="produceAdvancedComponents">produce Advanced Components</label>
                <input name="produceAdvancedComponents"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceAdvancedComponents")}
                       defaultChecked={this.state.configuration.produceAdvancedComponents}/>
                <br/>
                <label htmlFor="produceMineralsFromOres">produceMineralsFromOres</label>
                <input name="produceMineralsFromOres"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceMineralsFromOres")}
                       defaultChecked={this.state.configuration.produceMineralsFromOres}/>
                <br/>
                <label htmlFor="produceMineralsFromCompressedOres">produceMineralsFromCompressedOres</label>
                <input name="produceMineralsFromCompressedOres"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("produceMineralsFromCompressedOres")}
                       defaultChecked={this.state.configuration.produceMineralsFromCompressedOres}/>
                <br/>
                <br/>
                <label htmlFor="buySystem">buySystem</label>
                <SystemTypeahead
                    onChange={this.updateConfigTypeahead("buySystem", v => v.systemName)}
                    allSystems={this.state.allSystems}
                    initialValue={this.state.configuration.buySystem}
                />
                <br/>
                <label htmlFor="sellSystem">sellSystem</label>
                <SystemTypeahead
                    onChange={this.updateConfigTypeahead("sellSystem", v => v.systemName)}
                    allSystems={this.state.allSystems}
                    initialValue={this.state.configuration.sellSystem}
                />
                <br/>
                <Collapsable title="Manufacturing">
                    <label htmlFor="materialEfficiency">materialEfficiency</label>
                    <input name="materialEfficiency"
                           onChange={this.updateConfig("materialEfficiency", Number.parseInt)}
                           defaultValue={this.state.configuration.materialEfficiency}
                           min={0}
                           max={10}
                    />
                    <br/>
                    <label htmlFor="timeEfficiency">timeEfficiency</label>
                    <input name="timeEfficiency" onChange={this.updateConfig("timeEfficiency", Number.parseInt)}
                           defaultValue={this.state.configuration.timeEfficiency}
                           min={0}
                           max={20}
                           step={2}
                    />
                    <br/>
                    <label htmlFor="manufacturingSystem">manufacturingSystem</label>
                    <SystemTypeahead
                        onChange={this.updateConfigTypeahead("manufacturingSystem", v => v.systemName)}
                        allSystems={this.state.allSystems}
                        initialValue={this.state.configuration.manufacturingSystem}
                    />
                    <br/>
                </Collapsable>
                <Collapsable title="Time Efficiency">
                    {this.SkillLevel("levelIndustrySkill")}
                    <br/>
                    {this.SkillLevel("levelAdvancedIndustrySkill")}
                    <br/>
                    <label htmlFor="implantTimeModifier">implantTimeModifier</label>
                    <select name="implantTimeModifier"
                            onChange={this.updateConfigSelect("implantTimeModifier", v => v)}
                            defaultValue={this.state.configuration.implantTimeModifier}>
                        <option value="0">None</option>
                        <option value="1">Zainou 'Beancounter' Industry BX-801</option>
                        <option value="2">Zainou 'Beancounter' Industry BX-802</option>
                        <option value="4">Zainou 'Beancounter' Industry BX-804</option>
                    </select>
                    <br/>
                    {this.SkillLevel("levelAdvancedSmallShipConstructionSkill")}
                    <br/>
                    {this.SkillLevel("levelAdvancedMediumShipConstructionSkill")}
                    <br/>
                    {this.SkillLevel("levelAdvancedLargeShipConstructionSkill")}
                    <br/>
                    {this.SkillLevel("levelAdvancedCapitalShipConstructionSkill")}
                    <br/>
                    {this.SkillLevel("levelAdvancedIndustrialShipConstructionSkill")}
                    <br/>
                    {this.SkillLevel("levelAmarrStarshipEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelCaldariStarshipEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelGallenteStarshipEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelMinmatarStarshipEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelElectromagneticPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelElectronicEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelGravitonPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelHighEnergyPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelHydromagneticPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelLaserPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelMechanicalEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelMolecularEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelNaniteEngineeringSkill")}
                    <br/>
                    {this.SkillLevel("levelNuclearPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelPlasmaPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelQuantumPhysicsSkill")}
                    <br/>
                    {this.SkillLevel("levelRocketScienceSkill")}
                    <br/>
                    {this.SkillLevel("levelTriglavianQuantumEngineeringSkill")}
                </Collapsable>
                <Collapsable title="Reprocessing">
                    <label htmlFor="reprocessingStructureType">reprocessingStructureType</label>
                    <select name="reprocessingStructureType"
                            onChange={this.updateConfigSelect("reprocessingStructureType", s => s as StructureType)}
                            defaultValue={this.state.configuration.reprocessingStructureType}
                    >
                        <option value="NPC">NPC</option>
                        <option value="ATHANOR">ATHANOR</option>
                        <option value="TATARA">TATARA</option>
                    </select>
                    <br/>
                    <label htmlFor="reprocessingSecurityIndex">reprocessingSecurityIndex</label>
                    <select name="reprocessingSecurityIndex"
                            onChange={this.updateConfigSelect("reprocessingSecurityIndex", v => v)}
                            defaultValue={this.state.configuration.reprocessingSecurityIndex}>
                        <option value="HIGH_SEC">HIGH_SEC</option>
                        <option value="LOW_SEC">LOW_SEC</option>
                        <option value="NULL_SEC">NULL_SEC</option>
                        <option value="WORMHOLE">WORMHOLE</option>
                    </select>
                    <br/>
                    <label htmlFor="reprocessingRigLevel">reprocessingRigLevel</label>
                    <input name="reprocessingRigLevel"
                           onChange={this.updateConfig("reprocessingRigLevel", Number.parseInt)}
                           defaultValue={this.state.configuration.reprocessingRigLevel}
                           min={0}
                           max={2}
                    />
                    <br/>
                    <label htmlFor="reprocessingImplantLevel">reprocessingImplantLevel</label>
                    <select name="reprocessingImplantLevel"
                            onChange={this.updateConfigSelect("reprocessingImplantLevel", v => v)}
                            defaultValue={this.state.configuration.reprocessingImplantLevel}>
                        <option value="0">None</option>
                        <option value="1">Zainou 'Beancounter' Reprocessing RX-801</option>
                        <option value="2">Zainou 'Beancounter' Reprocessing RX-802</option>
                        <option value="4">Zainou 'Beancounter' Reprocessing RX-804</option>
                    </select>
                    <br/>
                    {this.SkillLevel("levelReprocessingSkill")}
                    <br/>
                    {this.SkillLevel("levelReprocessingEfficiencySkill")}
                    <br/>
                    {this.SkillLevel("levelReprocessingOreSpecificSkill")}
                </Collapsable>
            </>
        )
    }
}