import {Observable} from "../../util/Observable.ts";
import {AssetConfiguration} from "../../backend/interfaces/AssetConfiguration.ts";

export const AssetConfigurationObservable = new Observable<AssetConfiguration>({
    initialValue: {
        bpc: true,
        bpo: true,
        minerals: true,
        ores: true,
        ships: true,
        ice: true
    }
})