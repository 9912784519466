import React from "react";
import {RoutingConfiguration} from "../../backend/interfaces/RoutingConfiguration.ts";
import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import {Activity} from "../../backend/interfaces/Activity.ts";
import {SystemTypeahead} from "../SystemTypeahead.tsx";

type onChangeCallback = (newValue: RoutingConfiguration) => void

interface RoutingConfigurationProps {
    configuration: RoutingConfiguration;
    onChange: onChangeCallback;
    allSystems: SolarSystem[];
}

interface RoutingConfigurationState {
    configuration: RoutingConfiguration;
}

export class RoutingConfigurationComponent extends React.Component<RoutingConfigurationProps, RoutingConfigurationState> {
    constructor(props: RoutingConfigurationProps, context: any) {
        super(props, context);
        this.state = {configuration: this.props.configuration}
    }

    componentDidUpdate(prevProps: Readonly<RoutingConfigurationProps>, prevState: Readonly<RoutingConfigurationState>, snapshot?: any) {
        if (this.props.configuration !== prevState.configuration) {
            this.setState({configuration: this.props.configuration})
        }
    }

    private updateConfig(key: string, extractor: (s: string) => any) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newConfig = {...this.state.configuration}
            let newRawValue = ev.target.value;
            let newValue = extractor(ev.target.value);
            // @ts-ignore
            newConfig[key] = newRawValue;
            this.setState({configuration: newConfig})

            if (newRawValue === null || newRawValue === "") {
                return;
            }
            // @ts-ignore
            if (newValue === this.state.configuration[key]) {
                return;
            }

            // @ts-ignore
            newConfig[key] = newValue;

            this.props.onChange(newConfig);
        }
    }

    private updateConfigTypeahead(key: string, extractor: (s: any) => any) {
        return (selected: any) => {
            let newConfig = {...this.state.configuration}
            let newRawValue = selected;
            let newValue = extractor(selected);

            if (newRawValue === null) {
                return;
            }
            // @ts-ignore
            if (newValue === this.state.configuration[key]) {
                return;
            }

            // @ts-ignore
            newConfig[key] = newValue;

            this.props.onChange(newConfig);
        }
    }

    private updateConfigCheck(key: string) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newConfig = {...this.state.configuration}
            let newRawValue = ev.target.checked;
            let newValue = newRawValue;
            // @ts-ignore
            newConfig[key] = newRawValue;
            this.setState({configuration: newConfig})

            if (newRawValue === null) {
                return;
            }
            // @ts-ignore
            if (newValue === this.state.configuration[key]) {
                return;
            }

            // @ts-ignore
            newConfig[key] = newValue;

            this.props.onChange(newConfig);
        }
    }

    private updateConfigSelect(key: string, extractor: (s?: string) => any) {
        return (ev: React.ChangeEvent<HTMLSelectElement>) => {
            let newConfig = {...this.state.configuration}
            let newRawValue = ev.target.selectedOptions.item(0)?.value;
            let newValue = extractor(newRawValue);
            // @ts-ignore
            newConfig[key] = newRawValue;
            this.setState({configuration: newConfig})

            if (newRawValue === null || newRawValue === "") {
                return;
            }
            // @ts-ignore
            if (newValue === this.state.configuration[key]) {
                return;
            }

            // @ts-ignore
            newConfig[key] = newValue;

            this.props.onChange(newConfig);
        }
    }

    render() {
        return (
            <>
                <label htmlFor="startSystem">startSystem</label>
                <SystemTypeahead
                    onChange={this.updateConfigTypeahead("startSystem", v => v.systemName)}
                    allSystems={this.props.allSystems}
                    initialValue={this.props.configuration.startSystem}
                />
                <br/>
                <label htmlFor="endSystem">endSystem</label>
                <SystemTypeahead
                    onChange={this.updateConfigTypeahead("endSystem", v => v.systemName)}
                    allSystems={this.props.allSystems}
                    initialValue={this.props.configuration.endSystem}
                />
                <br/>
                <label htmlFor="maxDistance">maxDistance</label>
                <input name="maxDistance" onChange={this.updateConfig("maxDistance", Number.parseInt)}
                       defaultValue={this.state.configuration.maxDistance}
                       min={0}
                       max={50}
                />
                <br/>
                <label htmlFor="allowGating">allowGating</label>
                <input name="allowGating"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowGating")}
                       defaultChecked={this.state.configuration.allowGating}/>
                <br/>
                <label htmlFor="allowGatingToHighsec">allowGatingToHighsec</label>
                <input name="allowGatingToHighsec"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowGatingToHighsec")}
                       defaultChecked={this.state.configuration.allowGatingToHighsec}/>
                <br/>
                <label htmlFor="allowGatingToLowsec">allowGatingToLowsec</label>
                <input name="allowGatingToLowsec"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowGatingToLowsec")}
                       defaultChecked={this.state.configuration.allowGatingToLowsec}/>
                <br/>
                <label htmlFor="allowGatingToNullsec">allowGatingToNullsec</label>
                <input name="allowGatingToNullsec"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowGatingToNullsec")}
                       defaultChecked={this.state.configuration.allowGatingToNullsec}/>
                <br/>
                <label htmlFor="keepAboveOrEqualToSecurity">keepAboveOrEqualToSecurity</label>
                <input name="keepAboveOrEqualToSecurity"
                       onChange={this.updateConfig("keepAboveOrEqualToSecurity", Number.parseFloat)}
                       defaultValue={this.state.configuration.keepAboveOrEqualToSecurity}
                       min={-1}
                       max={1}
                       step={0.1}
                />
                <br/>
                <label htmlFor="allowJumping">allowJumping</label>
                <input name="allowJumping"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumping")}
                       defaultChecked={this.state.configuration.allowJumping}/>
                <br/>
                <label htmlFor="jumpRange">jumpRange</label>
                <input name="jumpRange" onChange={this.updateConfig("jumpRange", Number.parseInt)}
                       defaultValue={this.state.configuration.jumpRange}
                       min={0}
                       max={50}
                />
                <br/>
                <label htmlFor="allowJumpingToSystemWithoutStation">allowJumpingToSystemWithoutStation</label>
                <input name="allowJumpingToSystemWithoutStation"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumpingToSystemWithoutStation")}
                       defaultChecked={this.state.configuration.allowJumpingToSystemWithoutStation}/>
                <br/>
                <label htmlFor="allowJumpingToNpcStations">allowJumpingToNpcStations</label>
                <input name="allowJumpingToNpcStations"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumpingToNpcStations")}
                       defaultChecked={this.state.configuration.allowJumpingToNpcStations}/>
                <br/>
                <label htmlFor="allowJumpingToMediumPlayerStations">allowJumpingToMediumPlayerStations</label>
                <input name="allowJumpingToMediumPlayerStations"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumpingToMediumPlayerStations")}
                       defaultChecked={this.state.configuration.allowJumpingToMediumPlayerStations}/>
                <br/>
                <label htmlFor="allowJumpingToLargePlayerStations">allowJumpingToLargePlayerStations</label>
                <input name="allowJumpingToLargePlayerStations"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumpingToLargePlayerStations")}
                       defaultChecked={this.state.configuration.allowJumpingToLargePlayerStations}/>
                <br/>
                <label htmlFor="allowJumpingToExtraLargePlayerStations">allowJumpingToExtraLargePlayerStations</label>
                <input name="allowJumpingToExtraLargePlayerStations"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("allowJumpingToExtraLargePlayerStations")}
                       defaultChecked={this.state.configuration.allowJumpingToExtraLargePlayerStations}/>
                <br/>
                <label htmlFor="activity">activity</label>
                <select name="activity"
                        onChange={this.updateConfigSelect("activity", s => s as Activity)}
                        defaultValue={this.state.configuration.activity}
                >
                    <option value="NONE">NONE</option>
                    <option value="MANUFACTURING">MANUFACTURING</option>
                    <option value="RESEARCH_TIME">RESEARCH_TIME</option>
                    <option value="RESEARCH_MATERIAL">RESEARCH_MATERIAL</option>
                    <option value="COPYING">COPYING</option>
                    <option value="INVENTION">INVENTION</option>
                    <option value="REVERSE_ENGENEERING">REVERSE_ENGENEERING</option>
                    <option value="REACTIONS">REACTIONS</option>
                    <option value="REPROCESSING">REPROCESSING</option>
                    <option value="PLANETARY_INTERACTION">PLANETARY_INTERACTION</option>
                    <option value="ORE_REPROCESSING">ORE_REPROCESSING</option>
                </select>
                <br/>
            </>
        )
    }
}