import {checkLogin, LoggedInObservable} from "./backend/Login.ts";
import {BrowserRouter, Route} from 'react-router-dom';
import {Login, PrivateRoute} from "./components/Login";
import {AuthButton} from "./components/AuthButton.tsx";
import {PixmapGroup, PixmapLinkButton} from "./components/Pixmap.tsx";

function App() {
    checkLogin().then(value => LoggedInObservable.setValue(value));
    return (
        <BrowserRouter>
            <div className="page">
                <nav className="menu">
                    <PixmapGroup>
                        <PixmapLinkButton to="/characters" pixmap="construction" text="Characters"/>
                        <PixmapLinkButton to="/industry" pixmap="construction" text="Industry"/>
                        <PixmapLinkButton to="/routing" pixmap="directions_car" text="Routing"/>
                        <PixmapLinkButton to="/bpoContracts" pixmap="search" text="Bpo Contracts"/>
                        <PixmapLinkButton to="/assets" pixmap="warehouse" text="Assets"/>
                    </PixmapGroup>
                    <span className="variableSpacer"/>
                    <AuthButton/>
                </nav>
                <div className="container">
                    <Route path="/login" exact component={Login}/>
                    <PrivateRoute/>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
