import React from "react";
import {Typeahead} from "react-typeahead"
import {ItemNameStack} from "../../backend/interfaces/ItemNameStack.ts";
import {Item} from "../../backend/interfaces/Item.ts";
import {getAllItems} from "../../backend/Item.ts";
import {ItemPixmap, PixmapButton} from "../Pixmap.tsx";

export type CraftingItemSelectionChangeHandler = (items: ItemNameStack) => void

interface CraftingItemSelectionProps {
    onChange: CraftingItemSelectionChangeHandler,
    items: ItemNameStack
}

interface CraftingItemSelectionState {
    items: ItemNameStack
    name: string
    amount: number
    allItems: Item[]
}

export class CraftingItemSelectionComponent extends React.Component<CraftingItemSelectionProps, CraftingItemSelectionState> {

    constructor(props: CraftingItemSelectionProps) {
        super(props);
        this.state = {
            items: this.props.items,
            name: "",
            amount: 0,
            allItems: []
        }
    }

    componentDidMount() {
        getAllItems().then(v => this.setState({allItems: v}))
    }

    render() {
        return <table border={1}>
            {
                Object.entries(this.state.items)
                    .map(([key, amount]) => {
                        let item = this.state.allItems.find(v => v.item == key)
                        if (item == undefined) return <></>
                        return (<tr>
                            <td><ItemPixmap item={item}/></td>
                            <td>{amount}</td>
                            <td><PixmapButton pixmap={"remove"} onClick={this.remove(key)}/></td>
                        </tr>)
                    })
            }
            <tr>
                <td>
                    <Typeahead
                        options={this.state.allItems.filter(v => !v.isSkin)}
                        onOptionSelected={item => this.setState({name: item.item})}
                        displayOption={v => {
                            return <>
                                <ItemPixmap item={v}/>
                            </>
                        }}
                        inputDisplayOption={v => v.item}
                        filterOption={(v, o) => {
                            return o.item.startsWith(v);
                        }}
                        maxVisible={10}
                    />
                </td>
                <td>
                    <input
                        defaultValue={this.state.amount}
                        onChange={v => this.setState({amount: Number.parseInt(v.target.value)})}/>
                </td>
                <td><PixmapButton pixmap={"add"} onClick={this.add()}/></td>
            </tr>
        </table>
    }

    private add() {
        return (ev: React.MouseEvent) => {
            ev.preventDefault()
            ev.stopPropagation()
            let newItems = {...this.state.items}
            if (this.state.name in newItems) {
                newItems[this.state.name] += this.state.amount
            } else {
                newItems[this.state.name] = this.state.amount
            }

            this.setState({items: newItems})
            this.props.onChange(newItems)
        }
    }

    private remove(key: string) {
        return (ev: React.MouseEvent) => {
            ev.preventDefault()
            ev.stopPropagation()
            let newItems = {...this.state.items}
            delete newItems[key]

            this.setState({items: newItems})
            this.props.onChange(newItems)
        }
    }
}