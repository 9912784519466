import React from "react";
import {SystemInfoIndicator} from "./SystemInfoIndicator.tsx";
import {SolarSystem} from "../../../backend/interfaces/SolarSystem.ts";
import {getSecurityLevel} from "../GetSecurityLevel.ts";
import {formatSecurity} from "../../../util/Formatter.ts";

interface SystemSecurityIndicatorProps {
    system: SolarSystem
}

interface SystemSecurityIndicatorState {
}

export class SystemSecurityIndicator extends React.Component<SystemSecurityIndicatorProps, SystemSecurityIndicatorState> {
    constructor(props: SystemSecurityIndicatorProps, context: any) {
        super(props, context);
    }

    render() {
        let {roundedSecurity, securityLevel} = getSecurityLevel(this.props.system)

        return <SystemInfoIndicator
            palletIndex={securityLevel}
            infoText={formatSecurity(roundedSecurity / 10)}
            infoDescription={this.props.system.systemName}
        />
    }
}