import {BACKEND} from "./Constants.ts";
import {CraftingTreeWithOverflow} from "./interfaces/CraftingTree.ts";

import {CraftingTreeRequest} from "./interfaces/CraftingTreeRequest.ts";

export function getCraftingTreeWithOverflow(craftingTreeRequest: CraftingTreeRequest): Promise<CraftingTreeWithOverflow> {
    return fetch(BACKEND + "craftingTreeOverflow", {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(craftingTreeRequest),
        headers: {"Content-Type": "application/json"}
    })
        .then(value => value.json())
}