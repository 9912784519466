import React from "react";
import {RoutingConfiguration} from "../../backend/interfaces/RoutingConfiguration.ts";
import {Collapsable} from "../Collapsable.tsx";
import {RoutingConfigurationComponent} from "./RoutingConfigurationComponent.tsx";
import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import {getAllSystems} from "../../backend/Systems.ts";
import {getActivityIsochrones, getShortestRoute} from "../../backend/Routing.ts";
import {ActivityIsochrone} from "../../backend/interfaces/ActivityIsochrone.ts";
import {DotlanLink, DotlanLinkRoute} from "./DotlanLink.tsx";
import {RoutingStep} from "../../backend/interfaces/RoutingStep.ts";
import {DefaultMap, SciMap} from "./Map.tsx";
import {SystemCostIndicator} from "./indicators/SystemCostIndicator.tsx";
import {SystemSecurityIndicator} from "./indicators/SystemSecurityIndicator.tsx";

interface RoutingProps {
}

interface RoutingState {
    configuration: RoutingConfiguration;
    allSystems: SolarSystem[];
    shortestRoute: RoutingStep[];
    isochrones: ActivityIsochrone[];
}

export class Routing extends React.Component<RoutingProps, RoutingState> {
    constructor(props: RoutingProps, context: any) {
        super(props, context);
        this.state = {
            configuration: {
                startSystem: "Jita",
                endSystem: "Jita",
                maxDistance: 10,
                allowGating: true,
                allowGatingToHighsec: true,
                allowGatingToLowsec: false,
                allowGatingToNullsec: false,
                keepAboveOrEqualToSecurity: -1,
                allowJumping: false,
                jumpRange: 10,
                allowJumpingToSystemWithoutStation: false,
                allowJumpingToNpcStations: true,
                allowJumpingToMediumPlayerStations: true,
                allowJumpingToLargePlayerStations: true,
                allowJumpingToExtraLargePlayerStations: true,

                activity: "NONE"
            },
            allSystems: [],
            shortestRoute: [],
            isochrones: []
        }
    }

    componentDidMount() {
        getAllSystems().then(s => this.setState({allSystems: s}))
    }

    componentDidUpdate(prevProps: Readonly<RoutingProps>, prevState: Readonly<RoutingState>, snapshot?: any) {
        if (prevState.configuration != this.state.configuration) {
            getShortestRoute(this.state.configuration).then(v => this.setState({shortestRoute: v}))
            getActivityIsochrones(this.state.configuration).then(v => this.setState({isochrones: v}))
        }
    }

    render() {
        return (
            <form>
                <fieldset>
                    <Collapsable title="Configuration">
                        <RoutingConfigurationComponent
                            configuration={this.state.configuration}
                            allSystems={this.state.allSystems}
                            onChange={(newConfig) => this.setState({configuration: newConfig})}
                        />
                    </Collapsable>
                </fieldset>

                <fieldset>
                    <Collapsable title="ShortestPath">
                        <table border={1}>
                            <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Moving</th>
                                <th>Dotlan</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.shortestRoute.map(v => (
                                <tr key={v.from.systemId}>
                                    <td><SystemSecurityIndicator system={v.from}/></td>
                                    <td><SystemSecurityIndicator system={v.to}/></td>
                                    <td>{v.transition}</td>
                                    <td><DotlanLinkRoute routingSteps={[v]}/></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <DotlanLinkRoute routingSteps={this.state.shortestRoute}/>
                    </Collapsable>
                </fieldset>

                <fieldset>
                    <Collapsable title="Isochrones">
                        <table border={1}>
                            <thead>
                            <tr>
                                <th>System</th>
                                <th></th>
                                <th>System Cost Index</th>
                                <th>Distance</th>
                                <th>Stations</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.isochrones.map(v => (
                                <tr key={v.system.systemId}>
                                    <td><SystemSecurityIndicator system={v.system}/></td>
                                    <td><DotlanLink system={v.system}/></td>
                                    <td><SystemCostIndicator activityIsochrone={v} showTitle={false}/></td>
                                    <td>{v.jumps}</td>
                                    <td>
                                        <ol>
                                            {v.structures.map(v => <li>{v.name}</li>)}
                                        </ol>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Collapsable>
                </fieldset>

                <SciMap
                    systems={this.state.allSystems}
                    isochrones={this.state.isochrones}
                />
                <DefaultMap
                    systems={this.state.allSystems}
                    highlightedRoute={this.state.shortestRoute}
                    highlightedSystems={this.state.isochrones.map(v => v.system)}
                />
            </form>
        )
    }
}