import React from "react";
import {AssetConfiguration} from "../../backend/interfaces/AssetConfiguration.ts";
import {AssetConfigurationObservable} from "./AssetConfigurationObservable.ts";

interface AssetConfigurationProps {
}

interface AssetConfigurationState {
    configuration?: AssetConfiguration;
}

export class AssetConfigurationComponent extends React.Component<AssetConfigurationProps, AssetConfigurationState> {
    constructor(props: AssetConfigurationProps, context: any) {
        super(props, context);
        this.state = {}
    }

    private configurationListener = (value: AssetConfiguration) => {
        this.setState({
            configuration: value,
        });
    }

    componentDidMount() {
        AssetConfigurationObservable.subscribe(this.configurationListener);
    }

    componentWillUnmount() {
        AssetConfigurationObservable.unsubscribe(this.configurationListener);
    }

    private _updateConfig(key: string, extractedValue: any) {
        let updatedConfiguration = {...this.state.configuration!!}
        updatedConfiguration[key] = extractedValue
        AssetConfigurationObservable.setValue(updatedConfiguration)
    }

    private updateConfig(key: string, extractor: (s: string) => any) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newValue = extractor(ev.target.value);
            this._updateConfig(key, newValue);
        }
    }

    //
    // private updateConfigTypeahead(key: string, extractor: (s: any) => any) {
    //     return (selected: any) => {
    //         let newValue = extractor(selected);
    //         this._updateConfig(key, newValue);
    //     }
    // }

    private updateConfigCheck(key: string) {
        return (ev: React.ChangeEvent<HTMLInputElement>) => {
            let newRawValue = ev.target.checked;
            let newValue = newRawValue;
            this._updateConfig(key, newValue);
        }
    }

    // private updateConfigSelect(key: string, extractor: (s?: string) => any) {
    //     return (ev: React.ChangeEvent<HTMLSelectElement>) => {
    //         let newRawValue = ev.target.selectedOptions.item(0)?.value;
    //         let newValue = extractor(newRawValue);
    //         this._updateConfig(key, newValue);
    //     }
    // }

    private showEverything(ev: React.MouseEvent<HTMLButtonElement>) {
        ev.preventDefault()
        let updatedConfiguration = {...this.state.configuration!!}
        updatedConfiguration["bpc"] = false
        updatedConfiguration["bpo"] = false
        updatedConfiguration["minerals"] = false
        updatedConfiguration["ores"] = false
        updatedConfiguration["ships"] = false
        AssetConfigurationObservable.setValue(updatedConfiguration)
    }

    render() {
        if (this.state.configuration == undefined) return <></>

        return (
            <>
                <label htmlFor="bpc">bpc</label>
                <input name="bpc"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("bpc")}
                       checked={this.state.configuration.bpc}/>
                , oder
                <br/>
                <label htmlFor="bpo">bpo</label>
                <input name="bpo"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("bpo")}
                       checked={this.state.configuration.bpo}/>
                , oder
                <br/>
                <label htmlFor="minerals">minerals</label>
                <input name="minerals"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("minerals")}
                       checked={this.state.configuration.minerals}/>
                , oder
                <br/>
                <label htmlFor="ores">ores</label>
                <input name="ores"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("ores")}
                       checked={this.state.configuration.ores}/>
                , oder
                <br/>
                <label htmlFor="ice">ice</label>
                <input name="ice"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("ice")}
                       checked={this.state.configuration.ice}/>
                , oder
                <br/>
                <label htmlFor="ships">ships</label>
                <input name="ships"
                       type={"checkbox"}
                       onChange={this.updateConfigCheck("ships")}
                       checked={this.state.configuration.ships}/>
                , oder
                <br/>
                <button
                    onClick={ev => this.showEverything(ev)}
                >Show everything
                </button>
                <br/> und
                <br/>
                <label htmlFor="name">name</label>
                <input name="name"
                       onChange={this.updateConfig("name", v => v)}
                       defaultValue={this.state.configuration.name}
                />
            </>
        )
    }
}