import React from "react";
import {Character} from "../../backend/interfaces/Character.ts";
import {Skill} from "../../backend/interfaces/Skill.ts";
import {loadSkills, SkillObservable} from "../../backend/Skill.ts";
import {SkillComponent} from "./SkillComponent.tsx";
import {RouteComponentProps, withRouter} from "react-router-dom";
import {CharacterObservable, loadCharacters} from "../../backend/Character.ts";


interface CharacterSkillProps {
    character: Character
    skills: Skill[]
}

interface CharacterSkillState {
    search: string
}

export class CharacterSkill extends React.Component<CharacterSkillProps, CharacterSkillState> {
    constructor(props: CharacterSkillProps, context: any) {
        super(props, context);
        this.state = {search:""}
    }

    search = (ev:React.ChangeEvent<HTMLInputElement>) => {
        ev.preventDefault()
        this.setState({search: ev.target.value})
    }

    render() {
        if (this.props.skills.length == 0) return <></>

        let filteredSkills = this.props.skills.filter(v => v.name.includes(this.state.search) || v.skillGroupName.includes(this.state.search))
        let groups = [...new Set(filteredSkills.map(v => v.skillGroupName))]; // unique group names
        let sortedGroups = groups.sort((a, b) => a.localeCompare(b))

        return (
            <div className="skills">
                <label htmlFor="skillSearch">Suche:</label>
                <input
                    name="skillSearch"
                    defaultValue=""
                    onChange={ev => this.search(ev)}
                />
                {sortedGroups.map(groupName => {
                    let skillsInGroup = filteredSkills.filter(v => v.skillGroupName == groupName)
                        .sort((a, b) => a.name.localeCompare(b.name))
                    return (
                        <>
                            <h1>{groupName}</h1>
                            <div className="skillGroup">
                                {
                                    skillsInGroup.map(skill => {
                                        let trainedSkill = this.props.character.skills.find(v => v.skillId == skill.skillId)
                                        return (
                                            <SkillComponent skill={skill} trainedSkill={trainedSkill}/>
                                        )
                                    })
                                }
                            </div>
                        </>
                    )
                })}
            </div>
        )
    }
}

interface CharacterSkillPageProps {
    character: string
}

interface CharacterSkillPageState {
    skills: Skill[]
    characters: Character[]
}

export class CharacterSkillPageClazz extends React.Component<RouteComponentProps<CharacterSkillPageProps>, CharacterSkillPageState> {
    constructor(props: RouteComponentProps<CharacterSkillPageProps>, context: any) {
        super(props, context);
        this.state = {skills: [], characters: []}
    }

    skillListener = (skills: Skill[]) => {
        this.setState({skills: skills})
    }

    characterListener = (characters: Character[]) => {
        this.setState({characters: characters})
    }

    componentDidMount() {
        SkillObservable.subscribe(this.skillListener);
        CharacterObservable.subscribe(this.characterListener);

        if (SkillObservable.getValue().length == 0) {
            loadSkills().then(v => SkillObservable.setValue(v))
        }
        if (CharacterObservable.getValue().length == 0) {
            loadCharacters().then(v => CharacterObservable.setValue(v))
        }
    }

    componentWillUnmount() {
        SkillObservable.unsubscribe(this.skillListener);
        CharacterObservable.unsubscribe(this.characterListener);
    }

    render() {
        if (this.state.skills.length == 0 || this.state.characters.length == 0) return <></>

        let character = this.state.characters.find(v => v.characterId + "" == this.props.match.params.character)
        if (character == undefined) return <></>

        return <CharacterSkill
            character={character}
            skills={this.state.skills}
        />
    }
}

export const CharacterSkillPage = withRouter(CharacterSkillPageClazz)