import {ItemStack} from "../../backend/interfaces/ItemStack.ts";
import {CraftingTreeNode} from "../../backend/interfaces/CraftingTreeNode.ts";
import React from "react";
import {Collapsable} from "../Collapsable.tsx";
import {ItemTable} from "../ItemTable.tsx";
import {CraftingTreeNodeComponent} from "./CraftingTreeNodeComponent.tsx";

interface CollapsableCraftingTreeNodeProps {
    stack: ItemStack;
    node?: CraftingTreeNode;
}

interface CollapsableCraftingTreeNodeState {
}

export class CollapsableCraftingTreeNode extends React.Component<CollapsableCraftingTreeNodeProps, CollapsableCraftingTreeNodeState> {
    constructor(props: CollapsableCraftingTreeNodeProps, context: any) {
        super(props, context);
    }

    render() {
        if (!this.props.node) return <></>

        return (
            <Collapsable title={this.props.node.recipe?.outputs?.[0]?.item?.item || "**CollapsableCraftingTreeNode**"}>
                <ItemTable items={[this.props.stack]} showTotal={false}/>
                <CraftingTreeNodeComponent node={this.props.node}/>
            </Collapsable>
        );
    }
}