import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import {SystemColorPallet} from "./SystemColorPallet.ts";
import {ActivityIsochrone} from "../../backend/interfaces/ActivityIsochrone.ts";

export function getSecurityLevel(solarSystem: SolarSystem) {
    let roundedSecurity = Math.round(solarSystem.security * 10)

    let securityLevel: SystemColorPallet;
    if (roundedSecurity >= 8) {
        securityLevel = "highHighsec"
    } else if (roundedSecurity > 5) {
        securityLevel = "highsec"
    } else if (roundedSecurity == 5) {
        securityLevel = "lowHighsec"
    } else if (roundedSecurity >= 0 && Math.sign(solarSystem.security) > 0) {
        securityLevel = "lowsec"
    } else {
        securityLevel = "nullsec"
    }
    return {roundedSecurity, securityLevel};
}

export function getCostLevel(activityIsochrone?: ActivityIsochrone) {
    let costIndex = (activityIsochrone?.sci ?? 0) * 100

    let securityLevel: SystemColorPallet;
    if (costIndex > 7) {
        securityLevel = "nullsec"
    } else if (costIndex > 5) {
        securityLevel = "lowsec"
    } else if (costIndex > 4) {
        securityLevel = "lowHighsec"
    } else if (costIndex > 2) {
        securityLevel = "highsec"
    } else if (costIndex > 0) {
        securityLevel = "highHighsec"
    } else {
        securityLevel = "none"
    }
    return {costIndex, securityLevel};
}