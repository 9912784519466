import React, {Component} from "react";
import {Redirect, Route} from "react-router-dom";
import {LoggedInObservable, login, LoginData} from "../backend/Login.ts";
import {Observable} from "../util/Observable.ts";
import {CharacterPage} from "./character/CharacterPage.tsx";
import {CharacterSkillPage} from "./character/CharacterSkillPage.tsx";
import {Industry} from "./crafting/Industry.tsx";
import {Routing} from "./routing/Routing.tsx";
import {BpoContractPage} from "./BpoContract.tsx";
import {AssetsPage} from "./assets/AssetPage.tsx";
import {Index} from "./Index.tsx";

interface LoginProps {
    location: any
}

interface LoginState {
    loginError: boolean
    redirectToReferrer: boolean
    username: string,
    password: string
}

const OriginalRoute = new Observable<string>({})

export class Login extends React.Component<LoginProps, LoginState> {
    state = {
        loginError: false,
        redirectToReferrer: false,
        username: "",
        password: ""
    }
    login = () => {
        login(this.state.username, this.state.password)
            .catch(reason => {
                this.setState({loginError: true})
                return {loggedIn: false, username: this.state.username} as LoginData
            })
            .then(value => {
                LoggedInObservable.setValue(value);
                if (value.loggedIn) {
                    this.setState(() => ({
                        loginError: false,
                        redirectToReferrer: true
                    }))
                } else {
                    this.setState({loginError: true})
                }
            })
    }

    listener = (value: LoginData) => {
        console.log("Called Login listener with value", {...value})
        this.setState({redirectToReferrer: value.loggedIn});
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    render() {
        console.log("******", OriginalRoute.getValueMaybe(), window.location.pathname, this.props, this.state)
        const from = OriginalRoute.getValueMaybe() || '/'

        if (this.state.redirectToReferrer && from !== window.location.pathname) {
            return <Redirect to={from}/>
        }

        let errorMessage;
        if (this.state.loginError) {
            errorMessage = (
                <span className='error'>Anmeldung fehlgeschlagen.</span>
            );
        }

        return (
            <div className="pageContent">
                {errorMessage}
                <a href="/api/sso/login">
                    <img src="https://web.ccpgamescdn.com/eveonlineassets/developers/eve-sso-login-white-large.png"
                         alt="Log in with eveonline.com"/>
                </a>
            </div>
        )
    }
}

interface PrivateRouteState {
    loggedIn: boolean
}

export class PrivateRoute extends Component<any, PrivateRouteState> {

    constructor(props: Readonly<any>) {
        super(props);
        this.state = {loggedIn: false};
    }

    listener = (value: LoginData) => {
        console.log("Called PrivateRoute listener with value", value)
        this.setState({loggedIn: value.loggedIn});
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    render() {
        console.log("Rendering PrivateRoute with", window.location.pathname, this.props, this.state)

        if (!this.state.loggedIn) {
            if (!OriginalRoute.hasValue()) {
                OriginalRoute.setValue(window.location.pathname)
            }
            return <Redirect to="/login"/>
        }

        return <>
            <Route path="/characters" exact component={CharacterPage}/>
            <Route path="/character/:character/skills" component={CharacterSkillPage}/>
            <Route path="/industry" exact component={Industry}/>
            <Route path="/routing" exact component={Routing}/>
            <Route path="/bpoContracts" exact component={BpoContractPage}/>
            <Route path="/assets" exact component={AssetsPage}/>
            <Route path="/" exact component={Index}/>
        </>
    }
}