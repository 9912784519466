import {RecipeApplication} from "../../backend/interfaces/RecipeApplication.ts";
import React from "react";
import {ItemPixmap} from "../Pixmap.tsx";
import {formatAmount, formatTime} from "../../util/Formatter.ts";
import {groupBy} from "../../util/GroupBy.ts";

interface RecipeApplicationTableProps {
    recipes: RecipeApplication[],
}

interface RecipeApplicationTableState {
}

export class RecipeApplicationTable extends React.Component<RecipeApplicationTableProps, RecipeApplicationTableState> {
    constructor(props: RecipeApplicationTableProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return <>
            <table border={1}>
                <tr>
                    <th></th>
                    <th>Recipe</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Time</th>
                    <th>Time (Total)</th>
                </tr>
                {
                    this.props.recipes
                        .sort((a, b) => {
                            let aItem = a.recipe.usedBlueprint?.item || ""
                            let bItem = b.recipe.usedBlueprint?.item || ""
                            if (aItem > bItem) {
                                return 1
                            } else if (aItem < bItem) {
                                return -1
                            }
                            return 0;
                        })
                        .map(recipe => (
                                <tr key={recipe.recipe.usedBlueprint?.item}>
                                    <td>
                                        <ItemPixmap
                                            item={recipe.recipe.usedBlueprint}
                                            showName={false}
                                        />
                                    </td>
                                    <td>
                                        {recipe.recipe.usedBlueprint?.item}
                                    </td>
                                    <td>
                                        {formatAmount(recipe.amount)}
                                    </td>
                                    <td>
                                        {recipe.recipe.activity}
                                    </td>
                                    <td>
                                        {formatTime(recipe.recipe.time)}
                                    </td>
                                    <td>
                                        {formatTime(recipe.recipe.time * recipe.amount)}
                                    </td>
                                </tr>
                            )
                        )
                }
            </table>

            <table border={1}>
                <tr>
                    <th>Type</th>
                    <th>Time (Total)</th>
                </tr>
                {
                    Object.entries(groupBy(this.props.recipes, v => v.recipe.activity))
                        .map(([name, recipies]) =>
                            [name, recipies.map(it => it.recipe.time * it.amount).reduce((a, b) => a + b, 0)]
                        ).map(([name, time]) =>
                        <tr key={name}>
                            <td>{name}</td>
                            <td>{formatTime(time as number)}</td>
                        </tr>
                    )
                }
            </table>
        </>
    }
}