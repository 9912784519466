import React from "react";
import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import {RoutingStep} from "../../backend/interfaces/RoutingStep.ts";

interface DotlanLinkRouteProps {
    routingSteps: RoutingStep[]
}

interface DotlanLinkRouteState {
}

export class DotlanLinkRoute extends React.Component<DotlanLinkRouteProps, DotlanLinkRouteState> {
    constructor(props: DotlanLinkRouteProps, context: any) {
        super(props, context);
    }

    render() {
        if (this.props.routingSteps.length == 0) {
            return <></>
        }
        let joinedNames = ""
        for (let i = 0; i < this.props.routingSteps.length; i++) {
            let step = this.props.routingSteps[i];
            joinedNames += step.from.systemName.replace(" ", "_")
            if (step.transition == "GATING") {
                joinedNames += ":"
            } else {
                joinedNames += "::"
            }
        }

        joinedNames += this.props.routingSteps[this.props.routingSteps.length - 1].to.systemName.replace(" ", "_")
        let link = "https://evemaps.dotlan.net/route/" + joinedNames
        return <a href={link} target="_blank">Dotlan</a>
    }
}

interface DotlanLinkProps {
    system: SolarSystem
}

interface DotlanLinkState {
}

export class DotlanLink extends React.Component<DotlanLinkProps, DotlanLinkState> {
    constructor(props: DotlanLinkProps, context: any) {
        super(props, context);
    }

    render() {
        let regionName = this.props.system.regionName.replace(" ",'_')
        let systemName = this.props.system.systemName.replace(" ",'_')
        let link = "https://evemaps.dotlan.net/map/" + regionName + "/" + systemName;
        return <a href={link} target="_blank">Dotlan</a>
    }
}