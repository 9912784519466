import {BACKEND} from "./Constants.ts";
import {Character} from "./interfaces/Character.ts";
import {Observable} from "../util/Observable.ts";

export function loadCharacters(): Promise<Character[]> {
    return fetch(BACKEND + "characters", {
        method: "GET",
        credentials: 'include'
    })
        .then(value => value.json())
}

export const CharacterObservable = new Observable<Character[]>({initialValue: []})

export function fetchCharacterAssets(characterId: number): Promise<void> {
    return fetch(BACKEND + "characters/" + characterId + "/fetch/assets", {
        method: "POST",
        credentials: 'include'
    })
        .then(value => value.json())
}

export function fetchCharacterBlueprints(characterId: number): Promise<void> {
    return fetch(BACKEND + "characters/" + characterId + "/fetch/blueprints", {
        method: "POST",
        credentials: 'include'
    })
        .then(value => value.json())
}

export function fetchCorpAssets(characterId: number): Promise<void> {
    return fetch(BACKEND + "characters/" + characterId + "/fetch/corpAssets", {
        method: "POST",
        credentials: 'include'
    })
        .then(value => value.json())
}

export function fetchCorpBlueprints(characterId: number): Promise<void> {
    return fetch(BACKEND + "characters/" + characterId + "/fetch/corpBlueprints", {
        method: "POST",
        credentials: 'include'
    })
        .then(value => value.json())
}