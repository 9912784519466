export function groupBy<TItem>(data: TItem[], getGroupFromItem: (item: TItem) => string): { [key: string]: TItem[] } {
    return data.reduce((results: { [key: string]: TItem[] }, item) => {
            // Get the first instance of the key by which we're grouping
            let group = getGroupFromItem(item);

            // Ensure that there's an array to hold our results for this group
            results[group] = results[group] || [];

            // Add this item to the appropriate group within results
            results[group].push(item);

            // Return the updated results object to be passed into next reduce call
            return results;
        },

        // Initial value of the results object
        {}
    );
}