import {JobFee} from "../../backend/interfaces/JobFee.ts";
import React from "react";
import {Collapsable} from "../Collapsable.tsx";
import {formatAmount} from "../../util/Formatter.ts";

interface JobFeeComponentProps {
    jobFee: JobFee
}

interface JobFeeComponentState {
}

export class JobFeeComponent extends React.Component<JobFeeComponentProps, JobFeeComponentState> {
    constructor(props: JobFeeComponentProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <>
                <Collapsable title="Job Fees:">
                    <table border={1}>
                        <tr>
                            <th colSpan={2}>Estimated Item Value</th>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.estimatedItemValue)} ISK</td>
                        </tr>
                        <tr>
                            <th>System cost index</th>
                            <td className="itemTableRight">{this.props.jobFee.systemCostIndexPercentage}%EIV</td>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.systemCostIndexValue)} ISK</td>
                        </tr>
                        <tr>
                            <th>Structure Role Bonus</th>
                            <td className="itemTableRight">{this.props.jobFee.structureBonusPercentage}%SCI</td>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.structureBonusValue)} ISK</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Total Job Gros Cost</th>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.jobGrossCost)} ISK</td>
                        </tr>
                        <tr>
                            <th colSpan={3}>Taxes</th>
                        </tr>
                        <tr>
                            <th>Facility Tax</th>
                            <td className="itemTableRight">{this.props.jobFee.facilityTaxPercentage}%EIV</td>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.facilityTaxValue)} ISK</td>
                        </tr>
                        <tr>
                            <th>SCC Surcharge</th>
                            <td className="itemTableRight">{this.props.jobFee.sccSurchargePercentage}%EIV</td>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.sccSurchargeValue)} ISK</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Total Taxes</th>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.totalTaxes)} ISK</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Total Job Cost</th>
                            <td className="itemTableRight">{formatAmount(this.props.jobFee.totalJobCost)} ISK</td>
                        </tr>
                    </table>
                </Collapsable>
            </>
        )
    }
}