import {CraftingTreeNode} from "../../backend/interfaces/CraftingTreeNode.ts";
import React from "react";
import {Collapsable} from "../Collapsable.tsx";
import {ItemTable} from "../ItemTable.tsx";
import {CollapsableCraftingTreeNode} from "./CollapsableCraftingTreeNode.tsx";
import {RecipeComponent} from "./RecipeComponent.tsx";
import {JobFeeComponent} from "./JobFeeComponent.tsx";

interface CraftingTreeNodeComponentProps {
    node: CraftingTreeNode
}

interface CraftingTreeNodeComponentState {
}

export class CraftingTreeNodeComponent extends React.Component<CraftingTreeNodeComponentProps, CraftingTreeNodeComponentState> {
    constructor(props: CraftingTreeNodeComponentProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        return (
            <ol className="crafting-tree-node">
                {this.props.node.recipe != null &&
                    <li className="recipe">
                        <Collapsable title="Recipe" collapsed={true}>
                            <RecipeComponent recipe={this.props.node.recipe}/>
                        </Collapsable>
                    </li>
                }
                <li className="inputs">
                    <Collapsable title="Inputs">
                        <ItemTable
                            items={this.props.node.inputs.filter(v => v[1]?.recipe?.inputs === undefined).map(v => v[0])}
                            showTotal={false}/>
                        <ol className="inputsList">
                            {this.props.node.inputs
                                .filter(v => v[1]?.recipe?.inputs !== undefined)
                                .sort((a, b) => {
                                    if (a[0].item.item > b[0].item.item) {
                                        return 1
                                    } else if (a[0].item.item > b[0].item.item) {
                                        return -1
                                    }
                                    return 0;
                                }).map(value =>
                                    <li>
                                        <CollapsableCraftingTreeNode
                                            stack={value[0]}
                                            node={value[1]}
                                        />
                                    </li>)}
                        </ol>
                    </Collapsable>
                </li>
                <li className="outputs">
                    <Collapsable title="Outputs">
                        <ItemTable items={this.props.node.outputs} showTotal={false}/>
                    </Collapsable>
                </li>
                {
                    this.props.node.jobFee != null &&
                    <li className="jobFees">
                        <JobFeeComponent jobFee={this.props.node.jobFee}/>
                    </li>
                }
            </ol>
        )
    }
}