import {SolarSystem} from "../backend/interfaces/SolarSystem.ts";
import React from "react";
import {Typeahead} from "react-typeahead"


export type SystemSelectedHandler = (system: SolarSystem) => void

interface SystemTypeaheadProps {
    onChange: SystemSelectedHandler;
    allSystems: SolarSystem[];
    initialValue: string
}

interface SystemTypeaheadState {
}

export class SystemTypeahead extends React.Component<SystemTypeaheadProps, SystemTypeaheadState> {
    constructor(props: SystemTypeaheadProps, context: any) {
        super(props, context);
        this.state = {}
    }

    render() {
        return <Typeahead
            id="startSystem"
            options={this.props.allSystems}
            onOptionSelected={this.props.onChange}
            displayOption={v => `${v.systemName} (${v.constellationName}, ${v.regionName})`}
            inputDisplayOption={v => v.systemName}
            filterOption={(v, o) => {
                return o.systemName.startsWith(v) || o.constellationName.startsWith(v) || o.regionName.startsWith(v)
            }}
            maxVisible={10}
            initialValue={this.props.initialValue}
        />
    }
}
