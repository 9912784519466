import React from "react";
import {Redirect, RouteComponentProps, withRouter} from "react-router-dom";
import {LoggedInObservable, LoginData, logout} from "../backend/Login.ts";
import {formatAmount} from "../util/Formatter.ts";
import {PixmapButton, PixmapGroup} from "./Pixmap.tsx";

interface AuthButtonClazzState {
    redirectAddAlt: boolean
}

class AuthButtonClazz extends React.Component<RouteComponentProps<any>, LoginData & AuthButtonClazzState> {
    constructor(props: Readonly<RouteComponentProps<any>>) {
        super(props);
        this.state = {loggedIn: false, username: "", money: 0, redirectAddAlt: false}
    }

    render() {
        if (this.state.redirectAddAlt) return <Redirect to={"/api/sso/login"}/>
        if (this.state.loggedIn) {
            return (
                <>
                    <span>Willkommen {this.state.username}! Du hast {formatAmount(this.state.money)} ISK</span>
                    <PixmapGroup>
                        <PixmapButton onClick={event => this.addAlt()} pixmap="logout" text="Alt registrieren"/>
                        <PixmapButton onClick={event => this.logout()} pixmap="logout" text="Abmelden"/>
                    </PixmapGroup>
                    <div className="inner">

                    </div>
                </>
            );
        }
        return <span>You are not logged in.</span>
    }

    private logout() {
        logout().then(value => {
            LoggedInObservable.setValue({loggedIn: false, username: "", money: 0});
            this.props.history.push('/');
            this.setState({loggedIn: false, username: "", money: 0})
        })
    }

    private addAlt() {
        window.location.pathname = "/api/sso/login"
        this.setState({redirectAddAlt: true})
    }

    componentDidMount() {
        LoggedInObservable.subscribe(this.listener);
    }

    componentWillUnmount() {
        LoggedInObservable.unsubscribe(this.listener);
    }

    listener = (value: LoginData) => {
        console.log("Called AuthButtonClazz listener with value", value)
        this.setState(value);
    }
}

export const AuthButton = withRouter(AuthButtonClazz);