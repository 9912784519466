import React from "react";
import {Character} from "../../backend/interfaces/Character.ts";
import {CharacterObservable, loadCharacters} from "../../backend/Character.ts";
import {CharacterComponent} from "./Character.tsx";

interface CharacterPageProps {
}

interface CharacterPageState {
    characters: Character[]
}

export class CharacterPage extends React.Component<CharacterPageProps, CharacterPageState> {
    constructor(props: CharacterPageProps, context: any) {
        super(props, context);
        this.state = {characters: []}
    }

    listener = (characters: Character[]) => {
        this.setState({characters: characters})
    }

    componentDidMount() {
        CharacterObservable.subscribe(this.listener);
        loadCharacters().then(v => CharacterObservable.setValue(v))
    }

    componentWillUnmount() {
        CharacterObservable.unsubscribe(this.listener);
    }

    render() {
        return <div className="characters">
            {this.state.characters.map(v => <CharacterComponent
                key={v.characterId}
                character={v}
            />)}
        </div>
    }
}