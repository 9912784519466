import {BACKEND} from "./Constants.ts";
import {Observable} from "../util/Observable.ts";
import {Skill} from "./interfaces/Skill.ts";

export function loadSkills(): Promise<Skill[]> {
    return fetch(BACKEND + "skill/all", {
        method: "GET",
        credentials: 'include'
    })
        .then(value => value.json())
}

export const SkillObservable = new Observable<Skill[]>({initialValue: []})
