import React from "react";
import {PixmapButton} from "./Pixmap";

interface CollapsableProps extends React.PropsWithChildren {
    title: string
    collapsed?: boolean
}

interface CollapsableState {
    collapsed: boolean
}

export class Collapsable extends React.Component<CollapsableProps, CollapsableState> {
    constructor(props: CollapsableProps, context: any) {
        super(props, context);
        this.state = {
            collapsed: this.props.collapsed === undefined ? false : this.props.collapsed
        }
    }

    private toggleCollapsed(event: React.MouseEvent<HTMLLIElement>) {
        event.preventDefault();
        event.stopPropagation();
        this.setState({collapsed: !this.state.collapsed});
    }

    render() {
        return (
            <div className="collapsable">
                <div className="collapsableHeader">
                    <div className="collapsableTitle">
                        <h1>
                            {this.props.title}
                        </h1>
                    </div>
                    <div className="collapsableToggle">
                        <PixmapButton onClick={event => this.toggleCollapsed(event)}
                                      pixmap={this.state.collapsed ? "visibility" : "visibility_off"}
                                      text={this.state.collapsed ? "Show" : "Hide"}/>
                    </div>
                </div>
                {this.state.collapsed || this.props.children}
            </div>
        )
    }
}