import React from "react";
import {SystemColorPallet} from "../SystemColorPallet.ts";

interface SystemInfoIndicatorProps {
    palletIndex: SystemColorPallet
    infoText: string
    infoDescription: string;
}

interface SystemInfoIndicatorState {
}

export class SystemInfoIndicator extends React.Component<SystemInfoIndicatorProps, SystemInfoIndicatorState> {
    constructor(props: SystemInfoIndicatorProps, context: any) {
        super(props, context);
    }

    render() {
        return (
            <>
                <span className="systemInfo">
                    <span className={"systemInfoColor " + this.props.palletIndex}>&nbsp;</span>
                    <span className="systemInfoText">{this.props.infoText}</span>
                </span>
                <span className={"systemInfoDescription"}>{this.props.infoDescription}</span>
            </>
        )
    }
}