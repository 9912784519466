import {Observable} from "../../util/Observable.ts";
import {CraftingConfiguration} from "../../backend/interfaces/CraftingConfiguration.ts";

export const CraftingConfigurationObservable = new Observable<CraftingConfiguration>({
    initialValue: {
        materialEfficiency: 10,
        timeEfficiency: 20,
        structureType: "NPC",

        compositeReactionsTax: 0.0025,
        hybridReactionsTax: 0.0025,
        manufacturingTax: 0.0025,

        securityIndex: "HIGH_SEC",
        rigLevelCompositeReactions: 0,
        rigLevelHybridReactions: 0,
        rigLevelManufacturing: 0,

        producePI_0: false,
        producePI_1: false,
        producePI_2: false,
        producePI_3: false,
        producePI_4: false,
        produceReactions: false,
        produceFuelBlocks: false,
        produceComponents: false,
        produceAdvancedComponents: false,
        produceMineralsFromOres: false,
        produceMineralsFromCompressedOres: true,

        shippingCostInputPerM3: 0,
        shippingCostInputPerCollateral: 0,
        shippingCostOutputPerM3: 0,
        shippingCostOutputPerCollateral: 0,

        buySystem: "Jita",
        sellSystem: "Jita",
        manufacturingSystem: "Korama",
        reactionsSystem: "Korama",

        /////////////////////////////////
        // Time efficiency \/
        /////////////////////////////////

        levelIndustrySkill: 5,
        levelAdvancedIndustrySkill: 5,
        implantTimeModifier: 4,

        levelAdvancedSmallShipConstructionSkill: 5,
        levelAdvancedMediumShipConstructionSkill: 5,
        levelAdvancedLargeShipConstructionSkill: 5,
        levelAdvancedCapitalShipConstructionSkill: 5,
        levelAdvancedIndustrialShipConstructionSkill: 5,
        levelAmarrStarshipEngineeringSkill: 5,
        levelCaldariStarshipEngineeringSkill: 5,
        levelGallenteStarshipEngineeringSkill: 5,
        levelMinmatarStarshipEngineeringSkill: 5,
        levelElectromagneticPhysicsSkill: 5,
        levelElectronicEngineeringSkill: 5,
        levelGravitonPhysicsSkill: 5,
        levelHighEnergyPhysicsSkill: 5,
        levelHydromagneticPhysicsSkill: 5,
        levelLaserPhysicsSkill: 5,
        levelMechanicalEngineeringSkill: 5,
        levelMolecularEngineeringSkill: 5,
        levelNaniteEngineeringSkill: 5,
        levelNuclearPhysicsSkill: 5,
        levelPlasmaPhysicsSkill: 5,
        levelQuantumPhysicsSkill: 5,
        levelRocketScienceSkill: 5,
        levelTriglavianQuantumEngineeringSkill: 5,

        /////////////////////////////////
        // Time efficiency /\
        // Reprocessing    \/
        /////////////////////////////////

        reprocessingStructureType: "NPC",
        reprocessingSecurityIndex: "HIGH_SEC",
        reprocessingRigLevel: 0,
        reprocessingImplantLevel: 4,
        levelReprocessingSkill: 5,
        levelReprocessingEfficiencySkill: 5,
        levelReprocessingOreSpecificSkill: 5,
    }
})