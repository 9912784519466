import React from "react";
import {SystemInfoIndicator} from "./SystemInfoIndicator.tsx";
import {getCostLevel} from "../GetSecurityLevel.ts";
import {formatCost} from "../../../util/Formatter.ts";
import {ActivityIsochrone} from "../../../backend/interfaces/ActivityIsochrone.ts";

interface SystemCostIndicatorProps {
    activityIsochrone: ActivityIsochrone
    showTitle?: boolean
}

interface SystemCostIndicatorState {
}

export class SystemCostIndicator extends React.Component<SystemCostIndicatorProps, SystemCostIndicatorState> {
    constructor(props: SystemCostIndicatorProps, context: any) {
        super(props, context);
    }

    render() {
        let {costIndex, securityLevel} = getCostLevel(this.props.activityIsochrone)

        return <SystemInfoIndicator
            palletIndex={securityLevel}
            infoText={formatCost(costIndex)}
            infoDescription={(this.props.showTitle ?? true) ? "System Cost Index" : ""}
        />
    }
}