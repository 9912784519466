import {ItemStack} from "./ItemStack.ts";

export const CONTRACT_TYPE_ITEM_EXCHANGE = "ITEM_EXCHANGE"
export const CONTRACT_TYPE_AUCTION = "AUCTION"
export const CONTRACT_TYPE_COURIER = "COURIER"

export interface ContractItem {
    item: ItemStack;
    isBlueprintCopy: boolean;
    isIncluded: boolean;
    materialEfficiency: number;
    timeEfficiency: number;
    runs: number;
}

export interface Contract {
    id: string;
    type: string;
    price: number;
    buyout: number;
    collateral: number;
    reward: number;

    title: string;
    volume: number;
    issued: string;
    expired: string;

    items: ContractItem[];
}