import {BACKEND} from "./Constants.ts";
import {ItemStack} from "./interfaces/ItemStack.ts";
import {Item} from "./interfaces/Item.ts";


export function getItem(name: string): Promise<ItemStack> {
    return fetch(BACKEND + "item/" + name, {
        method: "GET",
        credentials: 'include'
    }).then(value => value.json())
}

export function getItemStack(name: string, count: number): Promise<ItemStack> {
    return fetch(BACKEND + "item/" + name + "/" + count, {
        method: "GET",
        credentials: 'include'
    }).then(value => value.json())
}
export function getAllItems(): Promise<Item[]> {
    return fetch(BACKEND + "item/all", {
        method: "GET",
        credentials: 'include'
    }).then(value => value.json())
}