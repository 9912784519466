import {BACKEND} from "./Constants.ts";
import {RoutingConfiguration} from "./interfaces/RoutingConfiguration.ts";
import {ActivityIsochrone} from "./interfaces/ActivityIsochrone.ts";
import {RoutingStep} from "./interfaces/RoutingStep.ts";

export function getActivityIsochrones(routingConfiguration: RoutingConfiguration): Promise<ActivityIsochrone[]> {
    return fetch(BACKEND + "route/isochrones/sci", {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(routingConfiguration),
        headers: {"Content-Type": "application/json"}
    })
        .then(value => value.json())
}

export function getShortestRoute(routingConfiguration: RoutingConfiguration): Promise<RoutingStep[]> {
    return fetch(BACKEND + "route/travel", {
        method: "POST",
        credentials: 'include',
        body: JSON.stringify(routingConfiguration),
        headers: {"Content-Type": "application/json"}
    })
        .then(value => value.json())
}