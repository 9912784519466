import React from "react";
import {PixmapLink} from "./Pixmap";
import {Character} from "../backend/interfaces/Character.ts";
import {CharacterObservable, loadCharacters} from "../backend/Character.ts";

interface IndexProps {
}

interface IndexState {
    characters: Character[]
}

export class Index extends React.Component<IndexProps, IndexState> {
    constructor(props: IndexProps, context: any) {
        super(props, context);
        this.state = {
            characters: []
        }
    }

    listener = (characters: Character[]) => {
        this.setState({characters: characters})
    }

    componentDidMount() {
        CharacterObservable.subscribe(this.listener);
        loadCharacters().then(v => CharacterObservable.setValue(v))
    }

    componentWillUnmount() {
        CharacterObservable.unsubscribe(this.listener);
    }


    render() {
        return (
            <>
                <ol>
                    <li><PixmapLink to="/characters" pixmap="construction" text="Characters"/></li>
                    <li><PixmapLink to="/industry" pixmap="construction" text="Industry"/></li>
                    <li><PixmapLink to="/routing" pixmap="directions_car" text="Routing"/></li>
                    <li><PixmapLink to="/bpoContracts" pixmap="search" text="Bpo Contracts"/></li>
                    <li><PixmapLink to="/assets" pixmap="warehouse" text="Assets"/></li>
                </ol>
            </>
        )
    }
}