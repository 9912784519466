import {Recipe} from "./Recipe.ts";
import {ItemStack} from "./ItemStack.ts";
import {JobFee} from "./JobFee";

export interface CraftingTreeNode {
    jobFee?: JobFee;
    recipe?: Recipe;
    inputs: [ItemStack, CraftingTreeNode?][];
    outputs: ItemStack[];
    time: number;
}

export type CraftingNodeMapper<T> = (node: CraftingTreeNode) => T

export function mapTree<T>(tree: CraftingTreeNode, mapper: CraftingNodeMapper<T>): T[] {
    let result: T[] = [];
    result.push(mapper(tree))
    for (let [k, v] of tree.inputs) {
        console.log(k, v)
        if (v != undefined) {
            result.push(...mapTree(v, mapper))
        }
    }
    return result;
}