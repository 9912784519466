import {Recipe} from "../../backend/interfaces/Recipe.ts";
import React from "react";
import {ItemPixmap} from "../Pixmap.tsx";
import {Collapsable} from "../Collapsable.tsx";
import {ItemTable} from "../ItemTable.tsx";

interface RecipeComponentProps {
    recipe: Recipe
}

interface RecipeComponentState {
}


export class RecipeComponent extends React.Component<RecipeComponentProps, RecipeComponentState> {
    constructor(props: RecipeComponentProps, context: any) {
        super(props, context);
    }

    render() {
        return (
            <ol className="recipe">
                <li className="blueprint item-stack">
                    Blueprint:
                    <ItemPixmap
                        item={this.props.recipe.usedBlueprint}
                        name={this.props.recipe.usedBlueprint?.item + " (" + this.props.recipe.activity + ")"}
                    />
                </li>
                <li className="inputs">
                    <Collapsable title="Inputs">
                        <ItemTable items={this.props.recipe.inputs}/>
                    </Collapsable>
                </li>
                <li className="outputs">
                    <Collapsable title="Outputs">
                        <ItemTable items={this.props.recipe.outputs} showTotal={false}/>
                    </Collapsable>
                </li>
            </ol>
        )
    }
}