import React from "react";
import {AssetTree} from "../../backend/interfaces/AssetTree.ts";
import {Collapsable} from "../Collapsable.tsx";
import {fetchPlayerAssets, loadPlayerAssets} from "../../backend/Assets.ts";
import {AssetTreeComponent} from "./AssetTreeComponent.tsx";
import {AssetConfigurationObservable} from "./AssetConfigurationObservable.ts";
import {AssetConfiguration} from "../../backend/interfaces/AssetConfiguration.ts";

interface AssetComponentState {
    assets: AssetTree[]
    configuration?: AssetConfiguration
}

export class AssetComponent extends React.Component<any, AssetComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            assets: []
        }
    }

    private configurationListener = (value: AssetConfiguration) => {
        this.setState({
            configuration: value,
        });
    }

    componentDidMount() {
        AssetConfigurationObservable.subscribe(this.configurationListener);
    }

    componentWillUnmount() {
        AssetConfigurationObservable.unsubscribe(this.configurationListener);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<AssetComponentState>, snapshot?: any) {
        if (this.state.configuration !== prevState.configuration) {
            this.loadAssets()
        }
    }

    private loadAssets() {
        loadPlayerAssets(AssetConfigurationObservable.getValue())
            .then(value => this.setState({assets: value}))
    }

    render() {
        return <div className="assets">
            {
                this.state.assets.map(asset => (
                        <Collapsable title={asset.containerName}>
                            <AssetTreeComponent asset={asset}/>
                        </Collapsable>
                    )
                )
            }
            <button onClick={() => fetchPlayerAssets().then(a => this.loadAssets())}>
                Load Assets from ESI. This will take a while...
            </button>
        </div>
    }
}