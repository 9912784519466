import {ItemStack} from "../backend/interfaces/ItemStack.ts";
import React from "react";
import {formatAmount, formatFraction} from "../util/Formatter.ts";
import {ItemPixmap} from "./Pixmap.tsx";

interface ItemTableProps {
    items: ItemStack[]
    showTotal?: boolean
    showPrices?: boolean
    showBlueprint?: boolean
}

interface ItemTableState {

}

export class ItemTable extends React.Component<ItemTableProps, ItemTableState> {
    constructor(props: ItemTableProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        let showPrices = this.props.showPrices ?? true
        let showBlueprint = this.props.showBlueprint ?? false

        let totalBuy = this.props.items.filter(input => input != undefined).map(input => input.price?.buy || 0).reduce((a, b) => a + b, 0);
        let totalSell = this.props.items.filter(input => input != undefined).map(input => input.price?.sell || 0).reduce((a, b) => a + b, 0);
        let totalVolume = this.props.items.filter(input => input != undefined).map(input => input.volume).reduce((a, b) => a + b, 0);
        return <table border={1}>
            <thead>
            <tr>
                <th></th>
                <th>Item</th>
                <th>Amount</th>
                <th>Volume (m³)</th>
                {showPrices && (<>
                        <th>Buy</th>
                        <th>#Buy-Orders</th>
                        <th>Sell</th>
                        <th>#Sell-Orders</th>
                        <th>Price-Location</th>
                    </>
                )}
                {showBlueprint && (<>
                        <th>ME</th>
                        <th>TE</th>
                        <th>Runs</th>
                    </>
                )}
            </tr>
            </thead>
            <tbody>
            {
                this.props.items.map((value, index) => {
                    return <tr key={index}>
                        <td>
                            <ItemPixmap
                                item={value.item}
                                showName={false}
                            />
                        </td>
                        <td>
                            {value.item.item}
                        </td>
                        <td className="itemTableRight">
                            {formatAmount(value.amount)}
                        </td>
                        <td className="itemTableRight">
                            {formatAmount(value.volume)}
                        </td>
                        {showPrices && (<>
                            <td className="itemTableRight">
                                {formatAmount(value.price?.buy)}
                            </td>
                            <td className="itemTableRight">
                                {formatAmount(value.price?.buyVolume)}
                            </td>
                            <td className="itemTableRight">
                                {formatAmount(value.price?.sell)}
                            </td>
                            <td className="itemTableRight">
                                {formatAmount(value.price?.sellVolume)}
                            </td>
                            <td className="itemTableRight">
                                {value.price?.location}
                            </td>
                        </>)}
                        {showBlueprint && (<>
                            <td className="itemTableRight">
                                {formatAmount(value.item.materialEfficiency)}
                            </td>
                            <td className="itemTableRight">
                                {formatAmount(value.item.timeEfficiency)}
                            </td>
                            <td className="itemTableRight">
                                {formatAmount(value.item.runs)}
                            </td>
                        </>)}

                    </tr>
                })
            }
            {(this.props.showTotal == true || this.props.showTotal == undefined) &&
                <tr>
                    <td></td>
                    <td>Total:</td>
                    <td></td>
                    <td className="itemTableRight">{formatAmount(totalVolume)}</td>
                    {showPrices && (<>
                        <td className="itemTableRight">{formatAmount(totalBuy)}</td>
                        <td></td>
                        <td className="itemTableRight">{formatAmount(totalSell)}</td>
                        <td className="itemTableRight">{formatFraction(totalSell / totalBuy * 100)}%</td>
                        <td></td>
                    </>)}
                    {showBlueprint && (<>
                        <td></td>
                        <td></td>
                        <td></td>
                    </>)}

                </tr>
            }
            </tbody>
        </table>
    }
}