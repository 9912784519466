const numberFormatter = new Intl.NumberFormat("de-DE", {maximumFractionDigits: 0})
const fractionFormatter = new Intl.NumberFormat("de-DE", {maximumFractionDigits: 2})
const securityFormatter = new Intl.NumberFormat("de-DE", {maximumFractionDigits: 1, minimumFractionDigits: 1})
const costFormatter = new Intl.NumberFormat("de-DE", {
    minimumSignificantDigits: 2,
    maximumSignificantDigits: 2,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
})

export function formatAmount(amount?: number): string {
    if (amount !== undefined) {
        return numberFormatter.format(amount);
    }
    return "0";
}

export function formatFraction(amount?: number): string {
    if (amount !== undefined) {
        return fractionFormatter.format(amount);
    }
    return "0";
}

export function formatSecurity(amount?: number): string {
    if (amount !== undefined) {
        return securityFormatter.format(amount);
    }
    return "0";
}

export function formatCost(amount?: number): string {
    if (amount !== undefined) {
        return costFormatter.format(amount);
    }
    return "0";
}

export function formatPercentage(amount?: number): string {
    return formatFraction((amount ?? 0) * 100) + "%"
}

export function formatTime(amount?: number): string {
    if (amount) {
        return (Math.floor(amount / 86400)) + "d " +
            (Math.floor((amount % 86400) / 3600)) + "h " +
            (Math.floor((amount % 3600) / 60) + "m");
    }
    return "0";
}