import React from "react";
import {SolarSystem} from "../../backend/interfaces/SolarSystem.ts";
import * as d3 from "d3";
import {ZoomTransform} from "d3";
import {getCostLevel, getSecurityLevel} from "./GetSecurityLevel.ts";
import {RoutingStep} from "../../backend/interfaces/RoutingStep.ts";
import {ActivityIsochrone} from "../../backend/interfaces/ActivityIsochrone.ts";
import {SystemSecurityIndicator} from "./indicators/SystemSecurityIndicator.tsx";
import {SystemCostIndicator} from "./indicators/SystemCostIndicator.tsx";

const securityLevelColors = {
    highHighsec: "#2697de",
    highsec: "darkgreen",
    lowHighsec: "yellow",
    lowsec: "orange",
    nullsec: "red",
    none: "black",
}

interface DefaultMapProps {
    systems: SolarSystem[]
    highlightedSystems: SolarSystem[]
    highlightedRoute: RoutingStep[]
}

export class DefaultMap extends React.Component<DefaultMapProps, MapState> {
    constructor(props: DefaultMapProps, context: any) {
        super(props, context);
    }

    render() {
        return <Map
            systems={this.props.systems}
            systemColor={(system) => {
                if (this.props.highlightedSystems.length == 0) {
                    return securityLevelColors[getSecurityLevel(system).securityLevel]
                } else {
                    return this.props.highlightedSystems.some(v => v.systemId == system.systemId) ? "blue" : "black"
                }
            }}
            systemSize={(system) => 2}
            edgeColor={(from, to) => {
                if (this.props.highlightedRoute.some(v => v.from.systemId == from?.systemId && v.to.systemId == to?.systemId)) return "blue"
                if (from?.regionId != to?.regionId) return "purple"
                if (from?.constellationId != to?.constellationId) return "red"
                return "black"
            }}
            edgeSize={(from, to) => {
                if (this.props.highlightedRoute.some(v => v.from.systemId == from?.systemId && v.to.systemId == to?.systemId)) return 2
                if (from?.regionId != to?.regionId) return 1.3
                if (from?.constellationId != to?.constellationId) return 1
                return 0.5
            }}
            renderSystemInfo={(system) => <SystemSecurityIndicator system={system}/>}
        />
    }
}


interface SciMapProps {
    systems: SolarSystem[]
    isochrones: ActivityIsochrone[];
}

export class SciMap extends React.Component<SciMapProps, MapState> {
    constructor(props: SciMapProps, context: any) {
        super(props, context);
    }

    private getIsochrone(system: SolarSystem): ActivityIsochrone | undefined {
        return this.props.isochrones.find(v => v.system.systemId == system.systemId)
    }

    private getSci(system: SolarSystem): number {
        let sci = this.getIsochrone(system)?.sci ?? 0
        return sci * 100
    }

    render() {
        return <Map
            systems={this.props.systems}
            systemColor={(system) => securityLevelColors[getCostLevel(this.getIsochrone(system)).securityLevel]}
            systemSize={(system) => {
                let sci = this.getSci(system)
                if (sci == 0) {
                    return 0.3;
                }
                return 5 / Math.max(1, Math.min(10, sci));
            }}
            edgeColor={(from, to) => "black"}
            edgeSize={(from, to) => 1}
            renderSystemInfo={(system) => {
                let isochrone = this.getIsochrone(system)
                return (
                    <>
                        <SystemSecurityIndicator system={system}/>
                        {isochrone && <><br/><SystemCostIndicator activityIsochrone={isochrone}/></>}
                        {isochrone &&
                            <ol>
                                {isochrone.structures.map(v => <li>{v.name}</li>)}
                            </ol>}
                    </>
                )
            }}
        />
    }
}

interface MapProps {
    systems: SolarSystem[]
    systemColor: (system: SolarSystem) => string
    systemSize: (system: SolarSystem) => number
    edgeColor: (from: SolarSystem | undefined, to: SolarSystem | undefined) => string
    edgeSize: (from: SolarSystem | undefined, to: SolarSystem | undefined) => number
    renderSystemInfo: (system: SolarSystem) => React.ReactNode
}

interface MapState {
    selectedSystem?: SolarSystem
}

export class Map extends React.Component<MapProps, MapState> {
    private ref: React.RefObject<SVGSVGElement>;
    private infoboxRef: React.RefObject<HTMLDivElement>;

    constructor(props: MapProps, context: any) {
        super(props, context);
        this.ref = React.createRef<SVGSVGElement>()
        this.infoboxRef = React.createRef<HTMLDivElement>()
        this.state = {}
    }

    componentDidUpdate(prevProps: Readonly<MapProps>, prevState: Readonly<MapState>, snapshot?: any) {
        if (
            (prevProps.systems != this.props.systems) ||
            (prevProps.systemColor != this.props.systemColor) ||
            (prevProps.systemSize != this.props.systemSize) ||
            (prevProps.edgeColor != this.props.edgeColor) ||
            (prevProps.edgeSize != this.props.edgeSize)
        ) {
            this.redraw()
        }
    }

    shouldComponentUpdate(nextProps: Readonly<MapProps>, nextState: Readonly<MapState>, nextContext: any): boolean {
        return (this.state.selectedSystem != nextState.selectedSystem) ||
            (nextProps.systems != this.props.systems) ||
            (nextProps.systemColor != this.props.systemColor) ||
            (nextProps.systemSize != this.props.systemSize) ||
            (nextProps.edgeColor != this.props.edgeColor) ||
            (nextProps.edgeSize != this.props.edgeSize)
    }

    componentDidMount() {
        this.redraw();
    }

    private redraw() {
        if (this.props.systems.length == 0) return
        const svg = d3.select(this.ref.current)
        svg.selectAll("*").remove()

        const systemsBySystemId = d3.index(this.props.systems, v => v.systemId)

        const drawableSystems = this.props.systems.filter(v => v.isNormalTravelDestination)

        const links = drawableSystems.flatMap(v => v.neighbors.map(n => ({
            source: v.systemId,
            target: n,
        })))

        const minX = drawableSystems.map(v => v.x).reduce((previousValue, currentValue) => Math.min(previousValue, currentValue), 1e20)
        const maxX = drawableSystems.map(v => v.x).reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), -1e20)
        const minZ = drawableSystems.map(v => v.z).reduce((previousValue, currentValue) => Math.min(previousValue, currentValue), 1e20)
        const maxZ = drawableSystems.map(v => v.z).reduce((previousValue, currentValue) => Math.max(previousValue, currentValue), -1e20)

        let outputWidth = this.ref.current?.clientWidth ?? 200
        let outputHeight = this.ref.current?.clientHeight ?? 200

        const lerp = function (outputMin: number, outputMax: number, inputMin: number, inputMax: number, input: number): number {
            const t = (input - inputMin) / (inputMax - inputMin);
            return (1 - t) * outputMin + outputMax * t;
        }

        let _nodes = d3.map(drawableSystems, system => {
            return ({
                id: system.systemId,
                name: system.systemName,
                cx: lerp(0, outputWidth, minX, maxX, system.x),
                cy: lerp(outputHeight, 0, minZ, maxZ, system.z),
                color: this.props.systemColor(system),
                radius: this.props.systemSize(system),
            })
        })
        let _links = d3.map(links, ({source, target}) => {
            const s = systemsBySystemId.get(source)
            const t = systemsBySystemId.get(target)
            return ({
                source: source,
                target: target,
                ss: s,
                tt: t,
                x1: lerp(0, outputWidth, minX, maxX, s?.x ?? 0),
                x2: lerp(0, outputWidth, minX, maxX, t?.x ?? 0),
                y1: lerp(outputHeight, 0, minZ, maxZ, s?.z ?? 0),
                y2: lerp(outputHeight, 0, minZ, maxZ, t?.z ?? 0),
                color: this.props.edgeColor(s, t),
                width: (s?.regionId != t?.regionId) ? 1 : (s?.constellationId != t?.constellationId) ? 0.7 : 0.5,
            })
        })

        const mouseover = () => {
            const that = this;

            return function (event, datum) {
                if (!datum) return
                if (!latestTransform) return

                let pos = latestTransform.apply(d3.pointer(event))

                that.infoboxRef.current!!.style.display = "block";
                that.infoboxRef.current!!.style.left = (pos[0] + 20) + "px";
                that.infoboxRef.current!!.style.top = (pos[1] + 20) + "px";

                that.setState({selectedSystem: systemsBySystemId.get(datum.id)})
            }
        }

        const mouseout = () => {
            const that = this;

            return function (event, datum) {
                that.infoboxRef.current!!.style.display = "hidden";
                that.infoboxRef.current!!.style.left = "0px";
                that.infoboxRef.current!!.style.top = "0px";
                that.setState({selectedSystem: undefined})
            }
        }

        const viewport = svg.attr("viewBox", [0, 0, outputWidth, outputHeight])

        const edgesBase = viewport.append("g")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 0)
            .attr("stroke-linecap", false);
        edgesBase
            .selectAll("line")
            .data(_links)
            .join("line")
            .attr("x1", d => d.x1)
            .attr("x2", d => d.x2)
            .attr("y1", d => d.y1)
            .attr("y2", d => d.y2)
            .attr("stroke", d => d.color)
            .attr("stroke-width", d => d.width)

        const nodesBase = viewport.append("g")
            .attr("stroke", "black")
            .attr("stroke-opacity", 1)
            .attr("stroke-width", 0.1)

        nodesBase
            .selectAll("circle")
            .data(_nodes)
            .join("circle")
            .attr("cx", d => d.cx)
            .attr("cy", d => d.cy)
            .attr("fill", d => d.color)
            .attr("r", d => d.radius)
            .on("mouseover", mouseover())
            .on("mouseout", mouseout())
        // .append("title").text(d => d.name)

        let latestTransform: ZoomTransform = new ZoomTransform(1, 0, 0);

        function zoomed({transform}) {
            edgesBase.attr("transform", transform)
            nodesBase.attr("transform", transform)
            latestTransform = transform
        }

        let zoomBehavior = d3.zoom()
            .translateExtent([[0, 0], [outputWidth, outputHeight]])
            .scaleExtent([1, 40])
            .on("zoom", zoomed);
        // @ts-ignore
        svg.call(zoomBehavior)
    }

    render() {
        return <div className="mapWrapper">
            <svg ref={this.ref}/>
            <div className="infoText" ref={this.infoboxRef}>
                {this.state.selectedSystem && this.props.renderSystemInfo(this.state.selectedSystem)}
            </div>
        </div>
    }
}