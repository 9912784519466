import {Contract} from "./interfaces/Contract.ts";
import {BACKEND} from "./Constants.ts";

export function getBpoContract(): Promise<Contract[]> {
    return fetch(BACKEND + "bpoContract/0", {
        method: "GET",
        credentials: 'include'
    }).then(value => value.json())
}

export function refreshContractCache() {
    fetch(BACKEND + "loadContractCache", {
        method: "POST",
        credentials: 'include'
    })
}