import React, {MouseEventHandler} from "react";
import {Link, Redirect} from "react-router-dom";
import {joinClasses} from "../util/JoinClasses.ts";
import {Item} from "../backend/interfaces/Item.ts";

export type Pixmaps =
    "add"
    | "back"
    | "brain"
    | "cancel"
    | "check"
    | "delete"
    | "edit"
    | "exit"
    | "forward"
    | "login"
    | "look"
    | "question"
    | "save";

export type Category = "icon" | "bp" | "bpc"

interface PixmapProps {
    pixmap: string;
    text?: string | JSX.Element;
    className?: string;
    autofocus?: boolean;
    tabIndex?: number;
    type?: 'submit' | 'reset' | 'button' | undefined;
}

interface ItemPixmapProps {
    item?: Item
    showName?: boolean;
    name?: string;
}

interface TypePixmapProps {
    pixmap: string
    category: Category;
    text: string
    title?: string
}

interface PixmapButtonProps extends PixmapProps {
    onClick: MouseEventHandler<any>;
    disabled?: boolean;
    grow?: boolean;
}

interface PixmapLinkProps extends PixmapProps {
    to: string;
}

export class Pixmap extends React.Component<PixmapProps, any> {
    render() {
        if (this.props.text) {
            return <span className={joinClasses("pixmap", this.props.className)}>
                <span className="material-icons pixmapIcon">{this.props.pixmap}</span>
                <span className="pixmapText">{this.props.text}</span>
            </span>
        } else {
            return <span className="material-icons pixmapIcon">{this.props.pixmap}</span>
        }
    }
}


export class ItemPixmap extends React.Component<ItemPixmapProps, any> {
    render() {
        if (this.props.item === undefined) {
            return <></>
        }
        let icon = this.props.item.icon
        let name = (this.props.showName ?? true) ? (this.props.name ?? this.props.item.item) : ""
        let category: Category = (this.props.item.isBpc) ? "bpc" :
            (this.props.item.isReactionOrBlueprint || this.props.item.isPi) ? "bp" : "icon"

        return <TypePixmap
            pixmap={icon}
            category={category}
            text={name}
        />
    }
}

export class TypePixmap extends React.Component<TypePixmapProps, any> {
    render() {
        return <>
            <img
                className="pixmapIcon"
                alt={this.props.pixmap + " icon"}
                src={"https://images.evetech.net/types/" + this.props.pixmap + "/" + this.props.category}
                title={this.props.title}
                width="32"
                height="32"/>
            <span className="pixmapText">{this.props.text}</span>
        </>
    }
}

export class PixmapButton extends React.Component<PixmapButtonProps, any> {
    render() {
        return (
            <button
                onClick={this.props.onClick}
                onSubmit={this.props.onClick}
                disabled={this.props.disabled}
                className={joinClasses("pixmapButton", this.props.className)}
                autoFocus={this.props.autofocus || false}
                tabIndex={this.props.tabIndex}
                type={this.props.type}
            >
                <Pixmap {...this.props} />
            </button>
        )
    }
}

export class PixmapLink extends React.Component<PixmapLinkProps, any> {
    render() {
        return (
            <Link className={joinClasses("pixmapLink", this.props.className)} to={this.props.to}>
                <Pixmap {...this.props} />
            </Link>
        )
    }
}

interface PixmapLinkButtonState {
    redirect: boolean
}

export class PixmapLinkButton extends React.Component<PixmapLinkProps, PixmapLinkButtonState> {
    constructor(props: PixmapLinkProps, context: any) {
        super(props, context);
        this.state = {redirect: false}
    }

    click = (ev: React.MouseEvent) => {
        ev.preventDefault()
        this.setState({redirect: true})
    }

    render() {
        if (this.state.redirect) {
            this.setState({redirect: false})
            return <Redirect to={this.props.to}/>
        }
        return (
            <PixmapButton
                onClick={ev => this.click(ev)}
                {...this.props}
            />
        )
    }
}

export class PixmapGroup extends React.Component<any, any> {
    render() {
        return <div className={joinClasses("pixmapGroup", this.props.className)}>
            {this.props.children}
        </div>
    }
}