import {BACKEND} from "./Constants.ts";
import {AssetTree} from "./interfaces/AssetTree.ts";
import {AssetConfiguration} from "./interfaces/AssetConfiguration.ts";

export function fetchPlayerAssets(): Promise<AssetTree[]> {
    return fetch(BACKEND + "assets/fetch", {
        method: "GET",
        credentials: 'include'
    })
        .then(value => value.json())
}

export function loadPlayerAssets(assetConfiguration: AssetConfiguration): Promise<AssetTree[]> {
    return fetch(BACKEND + "assets", {
        method: "POST",
        credentials: 'include',
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(assetConfiguration)
    })
        .then(value => value.json())
}