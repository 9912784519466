import React from "react";
import {Character} from "../../backend/interfaces/Character.ts";
import {PixmapButton, PixmapLinkButton, TypePixmap} from "../Pixmap.tsx";
import {
    CharacterObservable,
    fetchCharacterAssets,
    fetchCharacterBlueprints,
    fetchCorpAssets,
    fetchCorpBlueprints,
    loadCharacters
} from "../../backend/Character.ts";

interface CharacterProps {
    character: Character
}

interface CharacterState {
}

export class CharacterComponent extends React.Component<CharacterProps, CharacterState> {
    constructor(props: CharacterProps, context: any) {
        super(props, context);
    }

    fetchPlayerAssets(ev: React.MouseEvent<any>) {
        ev.preventDefault()
        fetchCharacterAssets(this.props.character.characterId).then(v => this.reloadCharacters())
    }

    fetchPlayerBlueprints(ev: React.MouseEvent<any>) {
        ev.preventDefault()
        fetchCharacterBlueprints(this.props.character.characterId).then(v => this.reloadCharacters())
    }

    fetchCorpAssets(ev: React.MouseEvent<any>) {
        ev.preventDefault()
        fetchCorpAssets(this.props.character.characterId).then(v => this.reloadCharacters())
    }

    fetchCorpBlueprints(ev: React.MouseEvent<any>) {
        ev.preventDefault()
        fetchCorpBlueprints(this.props.character.characterId).then(v => this.reloadCharacters())
    }

    reloadCharacters() {
        loadCharacters().then(v => CharacterObservable.setValue(v))
    }

    render() {
        return (
            <div className="character">
                <img
                    className="pixmapIcon"
                    alt={this.props.character.name + " icon"}
                    src={"https://images.evetech.net/characters/" + this.props.character.characterId + "/portrait?size=256"}
                    width="200"
                    height="200"
                />
                <img
                    className="pixmapIcon"
                    alt={this.props.character.corpName + " icon"}
                    src={"https://images.evetech.net/corporations/" + this.props.character.corpId + "/logo?size=128"}
                    width="100"
                    height="100"
                />
                <img
                    className="pixmapIcon"
                    alt={this.props.character.allianceName + " icon"}
                    src={"https://images.evetech.net/alliances/" + this.props.character.allianceId + "/logo?size=128"}
                    width="100"
                    height="100"
                />
                <div className="characterName">
                    <b>{this.props.character.name}</b><br/>
                    {this.props.character.corpName}<br/>
                    {this.props.character.allianceName}
                </div>
                <ol>
                    <li>
                        <TypePixmap pixmap={"4180"} category={"icon"} text="" title="LOGIN"/>
                        {this.props.character.lastLogin}
                    </li>
                    <li>
                        <TypePixmap pixmap={"52678"} category={"icon"} text="" title="STRUCTURE"/>
                        {this.props.character.checkpoints.find(v => v.type == "STRUCTURE")?.timestamp ?? "Nie"}
                    </li>
                    <li>
                        <TypePixmap pixmap={"35833"} category={"icon"} text="" title="STATION"/>
                        {this.props.character.checkpoints.find(v => v.type == "STATION")?.timestamp ?? "Nie"}
                    </li>
                    <li>
                        <TypePixmap pixmap={"3643"} category={"icon"} text="" title="PLAYER_ASSETS"/>
                        {this.props.character.checkpoints.find(v => v.type == "PLAYER_ASSETS")?.timestamp ?? "Nie"}
                        <PixmapButton onClick={ev => this.fetchPlayerAssets(ev)} pixmap="refresh"/>
                    </li>
                    <li>
                        <TypePixmap pixmap={"996"} category={"bp"} text="" title="PLAYER_BLUEPRINTS"/>
                        {this.props.character.checkpoints.find(v => v.type == "PLAYER_BLUEPRINTS")?.timestamp ?? "Nie"}
                        <PixmapButton onClick={ev => this.fetchPlayerBlueprints(ev)} pixmap="refresh"/>
                    </li>
                    <li>
                        <TypePixmap pixmap={"3643"} category={"icon"} text="" title="CORP_ASSETS"/>
                        {this.props.character.checkpoints.find(v => v.type == "CORP_ASSETS")?.timestamp ?? "Nie"}
                        <PixmapButton onClick={ev => this.fetchCorpAssets(ev)} pixmap="refresh"/>
                    </li>
                    <li>
                        <TypePixmap pixmap={"996"} category={"bp"} text="" title="CORP_BLUEPRINTS"/>
                        {this.props.character.checkpoints.find(v => v.type == "CORP_BLUEPRINTS")?.timestamp ?? "Nie"}
                        <PixmapButton onClick={ev => this.fetchCorpBlueprints(ev)} pixmap="refresh"/>
                    </li>
                    <li>
                        <PixmapLinkButton to={"/character/" + this.props.character.characterId + "/skills"}
                                          pixmap="search" text="Skills"/>
                    </li>
                </ol>
            </div>
        )
    }
}