import React from "react";
import {AssetConfigurationComponent} from "./AssetConfigurationComponent.tsx";
import {AssetComponent} from "./AssetComponent.tsx";

export class AssetsPage extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }

    render() {
        return <div className="assets">
            <AssetConfigurationComponent />
            <AssetComponent />
        </div>
    }
}