import React from "react";
import {
    Contract,
    CONTRACT_TYPE_AUCTION,
    CONTRACT_TYPE_COURIER,
    CONTRACT_TYPE_ITEM_EXCHANGE,
    ContractItem
} from "../backend/interfaces/Contract.ts";
import {getBpoContract, refreshContractCache} from "../backend/BpoContract.ts";
import {Category} from "./Pixmap.tsx";
import {formatAmount} from "../util/Formatter.ts";

interface ContractItemComponentProps {
    item: ContractItem
}

interface ContractItemComponentState {
}

class ContractItemComponent extends React.Component<ContractItemComponentProps, ContractItemComponentState> {
    constructor(props: ContractItemComponentProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        let category = "icon" as Category;
        if (this.props.item.item.item.isBlueprint) {
            category = this.props.item.isBlueprintCopy ? "bpc" : "bp";
        } else if (this.props.item.item.item.isReactionOrBlueprint) {
            category = "bp"
        }
        return <>{category}</> //TODO <ItemStackComponent itemStack={this.props.item.item} category={category}/>;
    }
}

interface ContractComponentProps {
    contract: Contract
}

interface ContractComponentState {
}

class ContractComponent extends React.Component<ContractComponentProps, ContractComponentState> {
    constructor(props: ContractComponentProps, context: any) {
        super(props, context);
        this.state = {};
    }

    render() {
        let price;
        if (this.props.contract.type == CONTRACT_TYPE_ITEM_EXCHANGE) {
            price = "Price: " + formatAmount(this.props.contract.price) + " ISK";
        } else if (this.props.contract.type == CONTRACT_TYPE_AUCTION) {
            price = "Buyout: " + formatAmount(this.props.contract.buyout) + " ISK";
        } else if (this.props.contract.type == CONTRACT_TYPE_COURIER) {
            price = "Collateral: " + formatAmount(this.props.contract.collateral) + " ISK, Reward: " + formatAmount(this.props.contract.reward) + " ISK";
        } else {
            return null;
        }

        return <li className="contract">
            <div className="contractHeader">
                <span className="contractTitle">{this.props.contract.title || "No Title"} </span>
                <button onClick={() => this.copyIngameLink()}>Copy</button>
                <br/>
                <span className="contractType">{this.props.contract.type}</span>&nbsp;
                <span className="contractPrice">{price}</span>
            </div>
            <ol>
                {this.props.contract.items.map(value => <ContractItemComponent item={value}/>)}
            </ol>
        </li>
    }

    public copyIngameLink() {
        navigator.clipboard.writeText("<a href=\"contract:30004735//" + this.props.contract.id + "\">" + (this.props.contract.title || "No Title") + "</a>");
    }
}

interface BpoContractListProps {
}

interface BpoContractListState {
    contracts?: Contract[]
}

export class BpoContractList extends React.Component<BpoContractListProps, BpoContractListState> {

    constructor(props: BpoContractListProps, context: any) {
        super(props, context);
        this.state = {};
    }

    componentDidMount() {
        getBpoContract().then(value => this.setState({contracts: value}))
    }


    render() {

        if (!this.state.contracts) {
            return <></>;
        }

        return <ol>
            {this.state.contracts.map(value => <ContractComponent contract={value}/>)}
        </ol>;
    }
}

export class BpoContractPage extends React.Component<any, any> {
    render() {
        return <div>
            <button onClick={() => refreshContractCache()}>Refresh Contracts. This will take a while...</button>
            <BpoContractList/>
        </div>
    }
}