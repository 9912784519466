import React from "react";
import {CraftingTreeComponent} from "./CraftingTreeNode";
import {CraftingConfigurationComponent} from "./CraftingConfigurationComponent.tsx";
import {CraftingItemSelectionComponent} from "./CraftingItemSelectionComponent.tsx";
import {ItemStack} from "../../backend/interfaces/ItemStack.ts";
import {ItemNameStack} from "../../backend/interfaces/ItemNameStack.ts";
import {ItemTable} from "../ItemTable.tsx";
import {formatAmount} from "../../util/Formatter.ts";
import {Collapsable} from "../Collapsable.tsx";
import {getItemStack} from "../../backend/Item.ts";

interface IndustryProps {
}

interface IndustryState {
    stacks: ItemStack[];
    items: ItemNameStack;
}

export class Industry extends React.Component<IndustryProps, IndustryState> {
    constructor(props: IndustryProps, context: any) {
        super(props, context);
        this.state = {
            stacks: [],
            items: {
                "Scimitar": 1
            }
        }
    }

    componentDidMount() {
        this.reloadItem();
    }

    componentDidUpdate(prevProps: Readonly<IndustryProps>, prevState: Readonly<IndustryState>, snapshot?: any) {
        if (this.state.items !== prevState.items) {
            this.reloadItem();
        }
    }

    render() {
        let itemView = this.state.stacks.map(stack => {
            return (
                <ol>
                    <li>
                        <ItemTable items={[stack]} showTotal={false} />
                    </li>
                    <li>PortionSize: {formatAmount(stack.item.portionSize)}</li>
                    <li>Is Mineral?: {stack.item.isMineral ? "yes" : "no"}</li>
                    <li>Is PI?: {stack.item.isPi ? "yes" : "no"}</li>
                    <li>Is Fuel Block?: {stack.item.isFuelBlock ? "yes" : "no"}</li>
                    <li>Is Ore?: {stack.item.isOre ? (stack.item.isUncompressedOre ? "Uncompressed" : stack.item.isCompressedOre ? "Compressed" : stack.item.isBatchCompressedOre ? "Batch Compressed" : "Oooops... This should be impossible") : "no"}</li>
                    <li>Is Component?: {stack.item.isComponent ? (stack.item.isAdvancedComponent ? "Advanced" : "yes") : "no"}</li>
                    <li>Is Blueprint?: {stack.item.isBlueprint ? "yes" : "no"}</li>
                </ol>
            );
        })

        return (
            <form>
                <fieldset>
                    <Collapsable title="Configuration:">
                        <CraftingConfigurationComponent/>
                    </Collapsable>
                </fieldset>

                <fieldset>
                    <Collapsable title="Stuff to craft">
                        <CraftingItemSelectionComponent
                            items={this.state.items}
                            onChange={v => this.changeItems(v)}
                        />
                    </Collapsable>
                </fieldset>

                <fieldset>
                    <Collapsable title="Item">
                        {itemView}
                    </Collapsable>
                </fieldset>

                <fieldset>
                    <CraftingTreeComponent
                        items={this.state.items}
                    />
                </fieldset>
            </form>
        )
    }

    private reloadItem() {
        Promise.all(Object.entries(this.state.items)
            .map(([key, amount]) => getItemStack(key, amount))
        ).then(v => this.setState({stacks: v}))
    }

    private changeItems(items: ItemNameStack) {
        this.setState({items: items})
    }
}