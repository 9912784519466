import React from "react";
import {TrainedSkill} from "../../backend/interfaces/Character.ts";
import {Skill} from "../../backend/interfaces/Skill.ts";

interface SkillComponentProps {
    skill: Skill
    trainedSkill?: TrainedSkill
}

interface SkillComponentState {
}

export class SkillComponent extends React.Component<SkillComponentProps, SkillComponentState> {
    constructor(props: SkillComponentProps, context: any) {
        super(props, context);
        this.state = {skills: []}
    }

    render() {
        let level = this.props.trainedSkill?.trainedLevel ?? 0
        return <div className="skill">
            <div className="skillName">{this.props.skill.name}</div>
            <div className="skillLevel">
                <div className="skillLevelText">{level} (x{this.props.skill.multiplier})</div>
                <div className={"progress progress" + level}>&nbsp;</div>
            </div>
        </div>
    }
}