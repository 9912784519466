import React from "react";
import {Collapsable} from "../Collapsable.tsx";
import {ItemTable} from "../ItemTable.tsx";
import {formatAmount, formatFraction} from "../../util/Formatter.ts";
import {mapTree} from "../../backend/interfaces/CraftingTreeNode.ts";
import {ItemNameStack} from "../../backend/interfaces/ItemNameStack.ts";
import {CraftingConfiguration} from "../../backend/interfaces/CraftingConfiguration.ts";
import {CraftingTreeWithOverflow} from "../../backend/interfaces/CraftingTree.ts";
import {getCraftingTreeWithOverflow} from "../../backend/CraftingTree.ts";
import {CraftingTreeNodeComponent} from "./CraftingTreeNodeComponent.tsx";
import {RecipeApplicationTable} from "./RecipeApplicationTable.tsx";
import {CraftingConfigurationObservable} from "./CraftingConfigurationObservable.ts";

interface CraftingTreeComponentProps {
    items: ItemNameStack;

}

interface CraftingTreeComponentState {
    tree?: CraftingTreeWithOverflow
    configuration?: CraftingConfiguration;
}

export class CraftingTreeComponent extends React.Component<CraftingTreeComponentProps, CraftingTreeComponentState> {
    constructor(props: CraftingTreeComponentProps, context: any) {
        super(props, context);
        this.state = {};
    }

    private configurationListener = (value: CraftingConfiguration) => {
        this.setState({
            configuration: value,
        });
    }

    componentDidMount() {
        CraftingConfigurationObservable.subscribe(this.configurationListener);
    }

    componentWillUnmount() {
        CraftingConfigurationObservable.unsubscribe(this.configurationListener);
    }

    componentDidUpdate(prevProps: Readonly<CraftingTreeComponentProps>, prevState: Readonly<CraftingTreeComponentState>, snapshot?: any) {
        if (this.props.items !== prevProps.items || this.state.configuration !== prevState.configuration) {
            this.reload();
        }
    }

    private reload() {
        if (this.state.configuration == undefined) return
        getCraftingTreeWithOverflow({
            items: this.props.items,
            craftingConfiguration: this.state.configuration
        }).then(value => {
            this.setState({tree: value});
        });
    }

    render() {
        if (this.state.tree == undefined || this.state.configuration == undefined) return <></>
        return (
            <ol>
                <li>
                    <Collapsable title="Tree">
                        <CraftingTreeNodeComponent node={this.state.tree?.tree}/>
                    </Collapsable>
                </li>
                <li>
                    <Collapsable title="Aggregated Inputs">
                        <ItemTable items={this.state.tree?.result}/>
                    </Collapsable>
                </li>
                <li>
                    <Collapsable title="Recipes">
                        <RecipeApplicationTable recipes={this.state.tree?.recipes}/>
                    </Collapsable>
                </li>
                <li>
                    <Collapsable title="Overflow">
                        <ItemTable items={this.state.tree?.overflow}/>
                    </Collapsable>
                </li>
                <li>
                    {this.summary()}
                </li>
            </ol>
        )
    }

    private summary() {
        let tree = this.state.tree!!

        let sum = function (a: number, b: number): number {
            return (a || 0) + (b || 0)
        }

        let inputCost = tree.result.map(v => v.price?.buy || 0).reduce(sum, 0)
        let jobCost = mapTree(tree.tree, v => v.jobFee?.totalJobCost || 0).reduce(sum, 0)
        let overflowResell = tree.overflow.map(v => v.price?.sell || 0).reduce(sum, 0)
        let outputSell = tree.tree.outputs.map(v => v.price?.sell || 0).reduce(sum, 0)
        let transportCost = tree.transportCostInput + tree.transportCostOutput
        let totalInputs = inputCost + jobCost + transportCost
        let totalOutputs = outputSell + overflowResell
        let delta = totalOutputs - totalInputs

        return <Collapsable title="Summary">
            <table border={1}>
                <tr>
                    <th>Input Costs</th>
                    <td className="itemTableRight">{formatAmount(inputCost)} ISK</td>
                </tr>
                <tr>
                    <th>Input Transport Costs</th>
                    <td className="itemTableRight">{formatAmount(tree.transportCostInput)} ISK</td>
                </tr>
                <tr>
                    <th>Job Costs</th>
                    <td className="itemTableRight">{formatAmount(jobCost)} ISK</td>
                </tr>
                <tr>
                    <th>Overflow Resell</th>
                    <td className="itemTableRight">{formatAmount(overflowResell)} ISK</td>
                </tr>
                <tr>
                    <th>Output Sell</th>
                    <td className="itemTableRight">{formatAmount(outputSell)} ISK</td>
                </tr>
                <tr>
                    <th>Output Transport Costs</th>
                    <td className="itemTableRight">{formatAmount(tree.transportCostOutput)} ISK</td>
                </tr>
                <tr>
                    <th>Delta Sell</th>
                    <td className="itemTableRight">{formatAmount(delta)} ISK</td>
                </tr>
                <tr>
                    <th>Delta Sell (%)</th>
                    <td className="itemTableRight">{formatFraction(100 * delta / totalInputs)}%</td>
                </tr>
            </table>
        </Collapsable>
    }
}